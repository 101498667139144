<template>
  <section class="py-5">
    <request-services
      ref="requestServices"
      title="Solicitudes de Servicio"
      :requestingUserModule="true"
    >
      <template v-slot:actions>
        <v-col cols="12" md="12" sm="12">
          <v-btn
            @click="openModalCreateRequestServices"
            color="primary"
            
            small
          >
            <v-icon small class="mr-1">
              mdi-plus
            </v-icon>
            Crear solicitud
          </v-btn>
        </v-col>
      </template>
    </request-services>
    <modal-create-request-service
      ref="modalCreateRequestService"
      :modalAction="dialogCreateRequestServices"
      :closeModalCreateRequestMaterials="closeModalCreateRequestServices"
      :typeRequest="TYPE_REQUESTS_MATERIALS.standard"
      :stockItemGroup="false"
      @requestServicesCreated="getRequestServices"
    ></modal-create-request-service>
  </section>
</template>

<script>
import RequestServices from "./quotes/index.vue";
import ModalCreateRequestService from "../requestMaterials/components/ModalCreateRequestMaterial.vue";
import { authUsuarioComputed } from "@/state/helpers";
import { TYPE_REQUESTS_MATERIALS } from "@/constants/requestMaterial";

export default {
  components: {
    RequestServices,
    ModalCreateRequestService,
  },
  data() {
    return {
      TYPE_REQUESTS_MATERIALS,
      dialogCreateRequestServices: false,
    };
  },
  methods: {
    openModalCreateRequestServices() {
      this.dialogCreateRequestServices = true;
      this.$refs.requestServices.filters.project_code = null;
      this.$refs.modalCreateRequestService.resetFormCreateRequestMaterial();
      this.$refs.requestServices.$refs.filterProject.getProjectsData({
        type: "clear",
      });
    },
    closeModalCreateRequestServices() {
      this.dialogCreateRequestServices = false;
    },
    async getRequestServices() {
      await this.$refs.requestServices.getRequestServicesQuotesData();
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style></style>
