<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalUpdateCommentService"
      @click:outside="executeCloseModalUpdateCommentService"
    >
      <v-card>
        <div class="p-4 pb-0 text-uppercase">
          <h5
            v-if="currentService"
            v-html="
              `Comentario de servicio <b>${getTitleFromCurrentService()}</b> `
            "
          ></h5>
        </div>
        <hr />
        <v-card-text class="pb-0">
          <v-container class="pb-0">
            <v-form class="" @submit.prevent="addCommentToService">
              <v-textarea
                v-model="$v.form.comment.$model"
                ref="commentInput"
                outlined
                cols="8"
                rows="4"
                placeholder="INGRESA AQUI EL COMENTARIO..."
                :error="$v.form.comment.$invalid && submitUpload"
                :error-messages="
                  $v.form.comment.$invalid && submitUpload
                    ? 'Este campo es requerido'
                    : null
                "
                @keypress.enter="() =>form.comment ? addCommentToService() : null"
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalUpdateCommentService"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
          <v-btn
            @click="addCommentToService"
            :disabled="!form.comment"
            :color="
              `${currentServiceHaveComment ? 'warning' : 'teal'} accent-4`
            "
            class="float-right text-white"
          >
            <small>
              {{
                currentServiceHaveComment
                  ? "Actualizar Comentario"
                  : "Agregar Comentario"
              }}
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalUpdateCommentService: {
      type: Function,
      default: () => {},
    },
    currentService: {
      default: null,
    },
  },
  data() {
    return {
      form: {
        comment: "",
      },
      submitUpload: false,
      commentInput: null,
    };
  },
  validations: {
    form: {
      comment: { required },
    },
  },
  methods: {
    getTitleFromCurrentService() {
      return this.currentService.name ? this.currentService.name : "-";
    },
    async addCommentToService() {
      this.submitUpload = true;
      if (!this.$v.form.$invalid) {
        this.currentService.comment = this.form.comment;
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Comentario agregado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.closeModalUpdateCommentService(true);
        this.resetForm();
      }
    },
    executeCloseModalUpdateCommentService($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalUpdateCommentService($event);
      }
    },
    resetForm() {
      this.form = {
        comment: "",
      };
      this.submitUpload = false;
    },
  },
  watch: {
    currentService(newService) {
      if (newService && newService.comment) {
        this.form.comment = newService.comment;
      } else {
        this.resetForm();
      }
    },
    modalAction: function(newVal) {
      if (newVal) {
      setTimeout(() => {
          requestAnimationFrame(() => {
            this.$refs.commentInput.focus();
          });
        }, 100);
      }
    },
  },
  computed: {
    currentServiceHaveComment() {
      return this.currentService?.comment ? true : false;
    },
  },
};
</script>

<style></style>
