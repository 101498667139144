<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-simple-table class="elevation-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Acción</th>
            <th class="text-left">Código de SAP</th>
            <th class="text-left">Descripción</th>
            <th class="text-center">Precio</th>
            <th class="text-left">Tipo de Cambio</th>
            <th class="text-right">Cantidad Solicitada</th>
            <th class="text-center">Estatus</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(service) in requestService.items"
            :key="service.id"
          >
            <td>
              <v-menu :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="blue darken-3" fab x-small outlined>
                    <v-icon color="white"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list rounded>
                  <v-list-item
                    class="mb-0"
                    v-if="
                      requestingUserModule &&
                        serviceIsStatusReady(service) &&
                        (requestIsStatusInUserValidationVariant(
                          requestService
                        ) ||
                          requestIsStatusInQuotation(requestService)) &&
                        service.to_quotation
                    "
                  >
                    <v-list-item-title
                      @click="updateStatusServiceToQuotation(service)"
                      x-small
                      class="pointer"
                    >
                      <span class="mr-2" style="font-size: 1.1rem"> ⚠️ </span>
                      Solicitar Nueva Cotización
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="mb-0"
                    v-if="
                      serviceIsStatusInReviewOrInQuotation(service) &&
                        !requestingUserModule
                    "
                  >
                    <v-list-item-title
                      @click="updateStatusService(service)"
                      x-small
                      class="pointer"
                    >
                      <span class="mr-2" style="font-size: 1.1rem"> 👌 </span>
                      Enviar Cotización a Validación
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="mb-0"
                    v-if="
                      serviceIsStatusInPurchaseValidationVariant(service) &&
                        !requestingUserModule
                    "
                  >
                    <v-list-item-title
                      @click="updateStatusServiceToApprove(service)"
                      x-small
                      class="pointer"
                    >
                      <span class="mr-2" style="font-size: 1.1rem"> 👌 </span>
                      Aprobar Cotización
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="mb-0"
                    v-if="
                      serviceIsStatusInPurchaseValidationVariant(service) &&
                        !requestingUserModule
                    "
                  >
                    <v-list-item-title
                      @click="updateStatusServiceToQuotation(service, true)"
                      x-small
                      class="pointer"
                    >
                      <span class="mr-2" style="font-size: 1.1rem"> ⚠️ </span>
                      Enviar a Cotización por Abastecimiento
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="mb-0 mt-0">
                    <v-list-item-title
                      @click="
                        openModalCommentsService(requestService.id, service)
                      "
                      x-small
                      class="pointer"
                    >
                      <v-badge
                        :content="
                          service.comments && service.comments.length
                            ? service.comments.length
                            : '0'
                        "
                        :color="
                          service.comments && service.comments.length
                            ? 'success'
                            : 'red'
                        "
                        overlap
                      >
                        <v-icon class="mr-2" color="primary">
                          mdi-comment-outline
                        </v-icon>
                      </v-badge>
                      <span class="mx-2"> Comentarios </span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                      icon
                      @click="
                        openModalFilesUploadService(requestService.id, service)
                      "
                      color="primary"
                      x-small
                      class="pointer"
                    >
                      <v-badge
                        bordered
                        :color="service.documents.length ? 'green' : 'red'"
                        :content="
                          service.documents.length
                            ? service.documents.length
                            : '0'
                        "
                        overlap
                      >
                        <v-icon class=""> mdi-file-multiple-outline </v-icon>
                      </v-badge>
                      <span class="mx-3"> Documentos </span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="mb-0"
                    v-if="
                      requestingUserModule &&
                        requestIsStatusInUserValidationVariant(requestService)
                    "
                  >
                    <v-list-item-title
                      @click="deleteService(service)"
                      x-small
                      class="pointer"
                    >
                      <v-icon class="mr-2" color="red">
                        mdi-delete-outline
                      </v-icon>
                      Eliminar Servicio
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <p class="my-0 text-left">
                {{ service.sap_code }}
              </p>
            </td>
            <td>
              <p class="text-left my-0">
                {{ service.name ? service.name : "-" }}
              </p>
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <p class="my-0 mt-1" style="font-size: 12px">
                  {{
                    service.unit_price
                      ? `$ ${new Intl.NumberFormat("de-DE").format(
                          Number(service.unit_price)
                        )}`
                      : "Por definir"
                  }}
                </p>
              </div>
            </td>
            <td>
              <p class="my-0 text-left">
                {{ service.coin ? service.coin : "CLP" }}
              </p>
            </td>
            <td>
              <p class="my-0 text-right">
                {{ service.quantity }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="updateQuantityService(service)"
                      v-if="
                        requestingUserModule &&
                          serviceIsStatusInQuotation(service)
                      "
                      v-on="on"
                      v-bind="attrs"
                      icon
                      x-small
                      color="red"
                      class="pointer"
                    >
                      <v-icon color="orange" class=""> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Modificar Cantidad</span>
                </v-tooltip>
              </p>
            </td>
            <td>
              <v-chip
                label
                small
                :color="getStatusColor(service.status)"
                class="mt-2 text-white"
              >
                {{ getStatusCorrection(service.status) }}
              </v-chip>
            </td>
          </tr>
          <tr v-if="requestService.items.length == 0">
            <td colspan="10" class="bg-white">
              <small>
                Sin Servicios {{ deletedItems ? "Eliminados" : "" }}
              </small>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <modal-comments-service
      :modalAction="dialogCommmentsService"
      :closeModalComments="closeModalCommentsService"
      :currentService="currentServiceComments"
      :requestId="currentRequestServiceId"
      @commentAdded="emitGetRequestsService"
    >
    </modal-comments-service>
    <modal-files-service
      ref="modalFilesUploadService"
      :modalAction="dialogFilesUploadService"
      :closeModalFilesService="closeModalFilesUploadService"
      :userCreateRequestService="false"
      :currentService="currentFilesUploadService"
      :currentRequest="requestService"
      :requestId="currentRequestServiceId"
      :requestingUserModule="requestingUserModule"
      :typeRequestService="requestService ? requestService.request_type : null"
      @filesUploaded="emitGetRequestsServiceAndUpdateFiles"
      @updateDocumentsCurrentService="emitGetRequestsServiceAndUpdateFiles"
    >
    </modal-files-service>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCommentsService from "./ModalCommentsService.vue";
import ModalFilesService from "./ModalFilesService.vue";
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";
import { mapActions } from "vuex/dist/vuex.common.js";
import { authUsuarioComputed } from "@/state/helpers";
export default {
  props: {
    requestService: {
      type: Object,
      required: true,
    },
    requestingUserModule: {
      type: Boolean,
      default: false,
    },
    deletedItems: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ModalCommentsService,
    ModalFilesService,
  },
  data() {
    return {
      loading: false,
      REQUEST_SERVICE_CONSTANTS,
      currentRequestServiceId: null,
      dialogCommmentsService: false,
      currentServiceComments: null,
      dialogFilesUploadService: false,
      currentFilesUploadService: null,
    };
  },
  methods: {
    ...mapActions({
      getExchangeRate: "requestService/getExchangeRate",
      sendUpdateStatusService: "requestService/updateStatusService",
      sendUpdateStatusServiceToQuotation:
        "requestService/updateStatusServiceToQuotation",
      sendUpdateStatusServiceToApproveFromPurchase:
        "requestService/updateStatusServiceToApproveFromPurchase",
      sendDeleteService: "requestService/deleteService",
      updateService: "requestService/updateService",
    }),
    formatAmount(amount, showDecimals = true) {
      return amount.toLocaleString("de-DE", {
        minimumFractionDigits: showDecimals? 2 : 0,
        maximumFractionDigits: showDecimals? 2 : 0,
      });
    },
    async mapExchangeRate(documents) {
      const coins = [
        ...new Set(
          documents
            .filter((item) => item.coin && item.coin !== "CLP")
            .map((item) => item.coin)
        ),
      ];
      let exchangeRates = [];
      const promises = coins.map(async (item) => {
        return this.getExchangeRate({ to_currency: item });
      });
      exchangeRates = await Promise.all(promises);
      const mapDocuments = documents.map((item) => {
        if (item.coin && item.coin !== "CLP") {
          const exchange = exchangeRates.find(
            (exchange) => exchange.to_currency == item.coin
          );
          return {
            ...item,
            coin: item.coin ? item.coin : "CLP",
            unit_amount: this.formatAmount(item.price, item.coin && item.coin !== "CLP"),
            amount: this.formatAmount(item.price * exchange.rate, false),
            exchange_rate: `1 ${item.coin} ⟺ ${exchange.rate
              .toString()
              .replaceAll(".", ",")} CLP`,
          };
        } else {
          return {
            ...item,
            coin: "CLP",
            unit_amount: this.formatAmount(item.price, false),
            amount: this.formatAmount(item.price, false),
            exchange_rate: "---",
          };
        }
      });

      return mapDocuments;
    },
    getStatusCorrection(status) {
      return REQUEST_SERVICE_CONSTANTS.getStatusCorrection(status);
    },
    openModalCommentsService(requestId, item) {
      this.currentRequestServiceId = requestId;
      this.currentServiceComments = item;
      this.dialogCommmentsService = true;
    },
    closeModalCommentsService() {
      this.currentServiceComments = null;
      this.dialogCommmentsService = false;
    },
    async openModalFilesUploadService(requestId, item) {
      this.currentRequestServiceId = requestId;
      let serviceUpdated = item;
      serviceUpdated.documents = await this.mapExchangeRate(
        serviceUpdated.documents
      );
      this.currentFilesUploadService = serviceUpdated;
      this.dialogFilesUploadService = true;
    },
    closeModalFilesUploadService() {
      this.currentFilesUploadService = null;
      this.dialogFilesUploadService = false;
    },
    emitGetRequestsService() {
      this.$emit("getRequestsService");
    },
    emitGetRequestsServiceAndUpdateFiles() {
      this.$emit("getRequestsServiceAndUpdateCurrentService");
    },
    getStatusColor(status) {
      return REQUEST_SERVICE_CONSTANTS.getStatusColor(status);
    },
    async updateFilesCurrentService() {
      let requestServiceIndex = this.requestServicesQuotes.items.findIndex(
        (item) => item.id == this.currentRequestServiceId
      );
      let serviceUpdated = this.requestServicesQuotes.items[
        requestServiceIndex
      ].items.find((item) => item.id == this.currentFilesUploadService.id);
      serviceUpdated.documents = await this.mapExchangeRate(
        serviceUpdated.documents
      );
      this.currentFilesUploadService = serviceUpdated;
      this.$refs.modalFilesUploadService.loadingDocument = false;
    },
    serviceIsStatusInQuotation(service) {
      return (
        service.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation
      );
    },
    serviceIsStatusInPurchaseValidationVariant(service) {
      return (
        service.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.purchase_validation_variant
      );
    },
    serviceIsStatusReady(service) {
      return (
        service.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.quotation_ready
      );
    },
    serviceIsStatusInReviewOrInQuotation(service) {
      return (
        service.status ===
          REQUEST_SERVICE_CONSTANTS.service_statuses.in_review ||
        service.status ===
          REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation
      );
    },
    requestIsStatusInPendingValidation(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.pending_validation
      );
    },
    requestIsStatusInUserValidationVariant(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation_variant
      );
    },
    requestIsStatusInQuotation(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation
      );
    },

    async updateQuantityService(service) {
      let self = this;
      this.$swal
        .fire({
          icon: "info",
          title: `Modifica la cantidad del servicio: <b>${
            service.name ? service.name : "-"
          }</b>?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "orange",
          confirmButtonText: `Modificar`,
          cancelButtonText: `Cancelar`,
          input: "text",
          inputValue: service.quantity,
          inputAttributes: {
            min: 1,
          },
          inputPlaceholder: "Cantidad del servicio",
          preConfirm: function(inputValue) {
            if (!inputValue || isNaN(inputValue) || inputValue < 1) {
              return self.$swal.showValidationMessage(
                "La cantidad debe ser mayor o igual a 1"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              service_id: service.id,
              user: self.user.email,
              quantity: result.value,
              documents: {},
            };
            const resp = await this.updateService(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Cantidad actualizada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.emitGetRequestsService();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusService(service) {
      if (
        !REQUEST_SERVICE_CONSTANTS.ifServiceHasQuotedFromTeamPurchase(service)
      ) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "El servicio debe tener al menos 1 cotización adjunta",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }
      if (
        REQUEST_SERVICE_CONSTANTS.ifServiceHasQuotedWithNoPriceOrSupplierFromTeamPurchase(
          service
        )
      ) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Todas las cotizaciones adjuntas deben tener un precio y proveedor definidos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estás seguro de Enviar el Servicio: <b>${
            service.name ? service.name : "-"
          }</b> a revisión por el usuario?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: `Enviar`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              item_id: service.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusService(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp?.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp?.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Cotización de servicio enviada a revisión correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.emitGetRequestsService();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusServiceToApprove(service) {
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estás seguro de Aprobar el Servicio: <b>${
            service.name ? service.name : "-"
          }</b>?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: `Aprobar`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              item_id: service.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusServiceToApproveFromPurchase(
              payload
            );
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp?.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp?.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Cotización de servicio aprobada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.emitGetRequestsService();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusServiceToQuotation(service, fromPurchase = false) {
      let title = `¿Estás seguro de ${
        !fromPurchase
          ? "Solicitar una Nueva Cotización para el"
          : "Enviar a Cotización por Abastecimento"
      } el Servicio: <b>${service.name ? service.name : "-"}</b>?`;
      this.$swal
        .fire({
          icon: "info",
          title,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: !fromPurchase ? "Solicitar" : "Enviar",
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: `Ingresa el feedback para el ${
            !fromPurchase ? "equipo de compras" : "usuario solicitante"
          }`,
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              item_id: service.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusServiceToQuotation(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              let message = !fromPurchase
                ? "Cotización de servicio solicitada correctamente, espera a que el equipo de compras lo cotize nuevamente"
                : "Servicio enviado a Cotización por Abastecimiento correctamente";
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: message,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$emit("getRequestServicesAndUpdateTable");
            }
            this.loading = false;
          }
        });
    },
    async deleteService(service) {
      this.$swal
        .fire({
          icon: "error",
          title: `¿Estás seguro de eliminar este servicio: <b>${service.name}</b>?, esta acción no puede ser deshacer`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Si, deseo eliminar el servicio",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              item_id: service.id,
              user: this.user.email,
              comment: "Servicio eliminado",
            };
            const resp = await this.sendDeleteService(payload);
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp && resp.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Servicio eliminado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.emitGetRequestsService();
            }
            this.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      requestServicesQuotes: "requestService/requestServicesQuotes",
    }),
  },
};
</script>

<style></style>
