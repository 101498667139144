<template>
  <div>
    <v-autocomplete
      v-model="ceco_code"
      clearable
      :items="cecos"
      @input="emitInputCeco"
      @update:search-input="getCecosData"
      @click:clear="
        getCecosData();
        $emit('cleanCeco');
      "
      class=""
      item-text="name_code"
      no-data-text="Sin CECOS"
      :loading="loadingCecos"
      :label="label"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getCecosData();
  },
  props: {
    cecos: {
      type: Array,
      default: () => [],
    },
    getCecosFunction: {
      type: Function,
      required: true,
      default: () => {},
    },
    label: {
      type: String,
      default: "Filtrar por CECO",
    },
  },
  data() {
    return {
      loadingCecos: false,
      debounceCecos: null,
      debounceCecosTime: 500,
      ceco_code: null,
    };
  },
  methods: {
    emitInputCeco() {
      this.$emit("inputCeco", this.ceco_code);
    },
    async getCecosData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.ceco_code !== search)
      ) {
        this.loadingCecos = true;
        clearTimeout(this.debounceCecos);
        let self = this;
        this.debounceCecos = setTimeout(async function() {
          await self.getCecosFunction({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingCecos = false;
        }, self.debounceCecosTime);
      }
    },
  },
};
</script>

<style></style>
