<template>
  <div>
    <v-autocomplete
      v-model="project_code"
      clearable
      :items="projects"
      @input="emitInputProject"
      @update:search-input="getProjectsData"
      @click:clear="
        getProjectsData();
        $emit('cleanProject');
      "
      class=""
      item-text="name_code"
      no-data-text="Sin proyectos"
      :loading="loadingProjects"
      :label="label"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getProjectsData();
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    getProjectsFunction: {
      type: Function,
      required: true,
      default: () => {},
    },
    label: {
      type: String,
      default: "Filtrar por Proyecto",
    },
  },
  data() {
    return {
      loadingProjects: false,
      debounceProjects: null,
      debounceProjectsTime: 500,
      project_code: null,
    };
  },
  methods: {
    emitInputProject() {
      this.$emit("inputProject", this.project_code);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjectsFunction({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceProjectsTime);
      }
    },
  },
};
</script>

<style></style>
