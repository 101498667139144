<template>
  <v-container class="p-3 mt-5">
    <loading-flux :value="loading"></loading-flux>
    <header class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </header>
    <v-row align-content="start" align-md="start" class="mt-1">
      <slot name="actions"></slot>
      <v-col cols="12" md="3" sm="12" class="py-0">
        <v-text-field
          v-model="filters.filter"
          @input="getRequestServicesQuotesData(1)"
          class="mt-5"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-autocomplete
          v-model="filters.status_filter"
          clearable
          :items="
            REQUEST_SERVICE_CONSTANTS.service_statuses_list().map((item) => ({
              name: getStatusCorrection(item),
              value: item,
            }))
          "
          item-text="name"
          item-value="value"
          @input="getRequestServicesQuotesData(1)"
          label="Filtrar por Estatus"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-autocomplete
          v-model="filters.business_unit"
          clearable
          :items="businessUnits"
          @input="getRequestServicesQuotesData(1)"
          class=""
          item-text="name"
          item-value="name"
          no-data-text="Sin Unidades de Negocio"
          label="Filtrar por Unidad de Negocio"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <filter-project
          ref="filterProject"
          :projects="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          :getProjectsFunction="getProjects"
          @cleanProject="cleanCurrentProject"
          @inputProject="
            updateCurrentProject($event);
            getRequestServicesQuotesData(1);
          "
        ></filter-project>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="">
        <filter-ceco
          :cecos="cecos.items ? cecos.items : []"
          :getCecosFunction="getCecos"
          @cleanCeco="cleanCurrentCeco"
          @inputCeco="
            updateCurrentCeco($event);
            getRequestServicesQuotesData(1);
          "
        ></filter-ceco>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-menu
          ref="menu"
          v-model="datepicker_menu_start"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_start_formatted"
              prepend-icon="mdi-calendar"
              readonly
              label="Filtrar por Fecha (Desde)"
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearDateFilters"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.date_start"
            scrollable
            locale="es"
            :max="maxDateStart"
            @input="
              datepicker_menu_start = false;
              getRequestServicesQuotesData(1);
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="pb-0">
        <v-menu
          ref="menu"
          v-model="datepicker_menu_end"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_end_formatted"
              prepend-icon="mdi-calendar"
              readonly
              label="Filtrar por Fecha (Hasta)"
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearDateFilters"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.date_end"
            scrollable
            locale="es"
            clearable
            :min="minDateEnd"
            @input="
              datepicker_menu_end = false;
              getRequestServicesQuotesData(1);
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :key="keyTableRequests"
      class="px-1 elevation-3 mt-3"
      :headers="tableHeaders"
      :items="requestServicesQuotes.items"
      :items-per-page="10"
      :page.sync="filters.page"
      hide-default-footer
      :loading="loadingQuotes"
      loading-text="Cargando Cotizaciones"
      item-key="id"
      show-expand
      :expanded.sync="expanded"
      no-data-text="Sin Cotizaciones"
    >
      <template v-slot:item="{ item, isExpanded }">
        <tr
          :class="rowColor(item)"
          @click="
            clickedRow(item);
            updateStatusInReviewRequest(item);
          "
        >
          <td>
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  class="blue darken-3"
                  fab
                  x-small
                  outlined
                >
                  <v-icon color="white"> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  class="mb-0 pl-1"
                  v-show="
                    (requestIsStatusUserValidationVariant(item) &&
                      requestingUserModule &&
                      !item.is_maverick) ||
                      (requestIsStatusInPurchaseValidation(item) &&
                        !requestingUserModule &&
                        item.is_maverick) ||
                      (requestIsStatusInPurchaseValidationVariant(item) &&
                        !requestingUserModule &&
                        !item.is_maverick)
                  "
                >
                  <v-list-item-title
                    @click="updateStatusRequest(item)"
                    x-small
                    class="pointer"
                  >
                    <span class="mr-2" style="font-size: 1.1rem"> 👌 </span>
                    Enviar Solicitud a Matriz de Aprobación
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="mb-0 pl-1"
                  v-if="
                    !item.is_maverick &&
                      requestIsStatusUserValidationVariant(item) &&
                      requestingUserModule
                  "
                >
                  <v-list-item-title
                    @click="updateStatusRequestToQuotation(item)"
                    x-small
                    class="pointer"
                  >
                    <span class="mr-2" style="font-size: 1.1rem"> ⚠️ </span>
                    Solicitar Recotización de Todos los Servicios
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="mb-0 pl-1"
                  v-if="
                    requestIsStatusInPurchaseValidation(item) &&
                      !requestingUserModule &&
                      item.is_maverick
                  "
                >
                  <v-list-item-title
                    @click="updateStatusRequestToReviewUser(item)"
                    x-small
                    class="pointer"
                  >
                    <span class="mr-2" style="font-size: 1.1rem"> ⚠️ </span>
                    Enviar Solicitud a Revisión por el Usuario
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="mb-0 pl-1"
                  v-if="
                    requestIsStatusInUserValidation(item) &&
                      requestingUserModule &&
                      item.is_maverick
                  "
                >
                  <v-list-item-title
                    @click="updateStatusRequestToReviewPurchase(item)"
                    x-small
                    class="pointer"
                  >
                    <span class="mr-2" style="font-size: 1.1rem"> 👌 </span>
                    <span> Enviar Solicitud a Revisión por Compras </span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="mb-0 pl-0"
                  v-if="
                    (requestIsStatusInUserValidation(item) &&
                      item.is_maverick &&
                      (requestingUserModule ||
                        REQUEST_SERVICE_CONSTANTS.isRequestCancelationHas14DaysDiffFromToday(
                          item
                        ))) ||
                      (!item.is_maverick &&
                        requestingUserModule &&
                        requestIsStatusUserValidationVariant(item))
                  "
                >
                  <v-list-item-title
                    @click="updateStatusRequestToRejection(item)"
                    x-small
                    class="pointer"
                  >
                    <v-icon class="text-danger mx-1">
                      mdi-close-circle-outline
                    </v-icon>
                    <span> Rechazar Solicitud </span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="mb-0 mt-0 pl-1">
                  <v-list-item-title
                    @click="openModalCommentsRequest(item)"
                    x-small
                    style="margin-top: -1rem"
                    class="pointer"
                  >
                    <v-badge
                      :content="
                        item.comments && item.comments.length
                          ? item.comments.length
                          : '0'
                      "
                      :color="
                        item.comments && item.comments.length
                          ? 'success'
                          : 'red'
                      "
                      overlap
                    >
                      <v-icon
                        class="mr-2"
                        color="primary"
                        style="font-size: 1.1rem; margin-top: -2px"
                      >
                        mdi-comment-outline
                      </v-icon>
                    </v-badge>
                    <span class="mx-2"> Comentarios </span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="mb-0 pl-1" v-if="!item.is_maverick">
                  <v-list-item-title
                    @click="openModalServicesDeleted(item)"
                    x-small
                    class="pointer"
                  >
                    <v-icon class="mr-2" color="red">
                      mdi-delete-outline
                    </v-icon>
                    Ver Servicios Eliminados
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td>
            <span class="mt-2" style="font-size: 10px">
              {{ item.order }}
            </span>
          </td>
          <td>
            <span class="mt-2" style="font-size: 10px">
              {{ item.project ? item.project.code : item.cost_center.code }}
            </span>
          </td>
          <td>
            <span class="mt-2" style="font-size: 11px">
              {{ item.sap_stage ? item.sap_stage : "Sin Etapa" }}
            </span>
          </td>
          <td>
            <span
              :class="{
                'text-primary font-weight-bold': item.is_maverick,
              }"
              class="mt-2"
              style="font-size: 11px"
            >
              {{
                !item.request_type
                  ? "-"
                  : item.request_type == REQUEST_MATERIAL
                  ? "SS"
                  : item.is_maverick
                  ? "MAVERICK"
                  : "SSU"
              }}
            </span>
          </td>
          <td>
            <span class="mt-2" style="font-size: 10px">
              {{ item.created_by }}
            </span>
          </td>
          <td>
            <span class="mt-2" style="font-size: 12px">
              {{ new Date(item.created_at).toLocaleDateString() }}
            </span>
          </td>
          <td>
            <v-chip
              v-if="
                item.approval_request &&
                  (item.approval_request.cancelled ||
                    item.approval_request.rejected ||
                    item.approval_request.sent_to_erp)
              "
              label
              small
              :color="getStatusColorApprovalRequest(item.approval_request)"
              class="mt-2 text-white"
            >
              {{
                item.approval_request.cancelled
                  ? "Anulada"
                  : item.approval_request.rejected
                  ? "Rechazada"
                  : item.approval_request.sent_to_erp
                  ? "Aprobada"
                  : "Pendiente"
              }}
            </v-chip>
            <v-chip
              v-else
              label
              small
              :color="getStatusColor(item.status)"
              class="mt-2 text-white"
            >
              {{ getStatusCorrection(item.status) }}
            </v-chip>
          </td>
          <td>
            <v-icon>
              {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="bg-gray">
          <section class="my-5 px-3">
            <table-services-detail
              ref="tableServicesDetail"
              :requestingUserModule="requestingUserModule"
              :request-service="item"
              @getRequestServicesAndUpdateTable="
                getRequestServicesQuotesData(filters.page, false, true)
              "
              @getRequestsService="getRequestServicesQuotesData(filters.page)"
              @getRequestsServiceAndUpdateCurrentService="
                getRequestServicesQuotesData(filters.page, true, false)
              "
              class="mt-5"
            ></table-services-detail>
          </section>
        </td>
      </template>
    </v-data-table>
    <div class="my-3 float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          requestServicesQuotes.total_pages
            ? requestServicesQuotes.total_pages
            : 1
        "
      ></v-pagination>
    </div>
    <modal-comments-service
      :modalAction="dialogCommmentsRequest"
      :closeModalComments="closeModalCommentsRequest"
      :currentRequest="currentRequest"
      @commentAdded="getRequestServicesQuotesData(filters.page)"
    >
    </modal-comments-service>
    <modal-services-deleted
      :modalAction="dialogServicesDeleted"
      :closeModalServicesDeleted="closeModalServicesDeleted"
      :currentRequest="currentRequest"
    >
    </modal-services-deleted>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import FilterProject from "@/components/commons/projects/FilterProject.vue";
import FilterCeco from "@/components/commons/cecos/FilterCeco.vue";
import TableServicesDetail from "../components/TableServicesDetail.vue";
import {
  REQUEST_MATERIAL,
  STATUS_REQUESTS_MATERIALS,
} from "@/constants/requestMaterial";
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";
import ModalCommentsService from "../components/ModalCommentsService.vue";
import ModalServicesDeleted from "../components/ModalServicesDeleted.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Cotizaciones",
    },
    requestingUserModule: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterProject,
    FilterCeco,
    TableServicesDetail,
    ModalCommentsService,
    ModalServicesDeleted,
  },
  mounted() {
    this.getBusinessUnits();
    this.getRequestServicesQuotesData();
  },
  data() {
    return {
      loading: false,
      REQUEST_MATERIAL,
      REQUEST_SERVICE_CONSTANTS,
      dialogCommmentsRequest: false,
      dialogServicesDeleted: false,
      currentRequest: null,
      statusRequestsApprovalRequest: STATUS_REQUESTS_MATERIALS,
      loadingQuotes: false,
      debounceTime: 500,
      keyTableRequests: 1000,
      debounceRequests: null,
      debounceQuotes: null,
      breadcrumb: [
        {
          text: "Inicio",
          disabled: false,
          href: "/",
        },
        {
          text: this.title,
          disabled: true,
          href: "/requestServices/quotes/list",
        },
      ],
      expanded: [],
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "action",
          sortable: false,
        },
        {
          text: "Cesta de Compra",
          align: "center",
          value: "order",
          sortable: false,
        },
        {
          text: "Proyecto/CECO",
          align: "center",
          value: "code",
          sortable: false,
        },
        {
          text: "Etapa del Proyecto",
          align: "center",
          value: "stage",
          sortable: false,
        },
        {
          text: "Tipo de Solicitud",
          align: "center",
          value: "material_request_type",
          sortable: false,
        },
        {
          text: "Usuario que Solicita",
          align: "center",
          value: "user",
          sortable: false,
        },
        {
          text: "Fecha de Creación",
          align: "center",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Estatus",
          align: "center",
          value: "status",
          sortable: false,
        },
        { text: "Servicios", align: "center", value: "data-table-expand" },
      ],
      datepicker_menu_start: false,
      datepicker_menu_end: false,
      filters: {
        page: 1,
        only_pending: false,
        filter: "",
        date_start: null,
        date_end: null,
        business_unit: null,
        project_code: null,
        ceco_code: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getRequestServicesQuotes: "requestService/getRequestServicesQuotes",
      getExchangeRate: "requestService/getExchangeRate",
      getProjects: "requestMaterialsSap/getProjects",
      getCecos: "requestMaterialsSap/getCecos",
      getBusinessUnits: "requestMaterial/getBusinessUnits",
      sendUpdateRequestToPendingApproval:
        "requestService/updateStatusRequestToPendingApproval",
      sendUpdateRequestMaverickToPendingApproval:
        "requestService/updateStatusRequestMaverickToPendingApproval",
      sendUpdateStatusRequestToReview:
        "requestService/updateStatusRequestToReview",
      sendUpdateStatusRequestMaverickToReviewUser:
        "requestService/updateStatusRequestMaverickToReviewUser",
      sendUpdateStatusRequestMaverickToReviewPurchase:
        "requestService/updateStatusRequestMaverickToReviewPurchase",
      sendUpdateStatusRequestMaverickToRejection:
        "requestService/updateStatusRequestMaverickToRejection",
      sendUpdateStatusRequestToRejection:
        "requestService/updateStatusRequesToRejection",
      sendUpdateStatusRequestToQuotation:
        "requestService/updateStatusRequestToQuotation",
      sendUpdateStatusRequestToApproveFromPurchase:
        "requestService/updateStatusRequestToApproveFromPurchase",
    }),
    rowColor(item) {
      if (item.is_maverick) {
        return "bg-maverick";
      }
      return "";
    },
    getStatusColorApprovalRequest(item) {
      if (item && (item.rejected || item.cancelled)) {
        return "red darken-2";
      }
      switch (item.sent_to_erp) {
        case this.statusRequestsApprovalRequest.pending:
          return "yellow darken-4";
        case this.statusRequestsApprovalRequest.accepted:
          return "blue darken-2";
        default:
          break;
      }
    },
    getStatusCorrection(status) {
      return REQUEST_SERVICE_CONSTANTS.getStatusCorrection(status);
    },
    clickedRow(value) {
      if (this.expanded.find((item) => item.id == value.id)) {
        return (this.expanded = this.expanded.filter(
          (item) => item.id !== value.id
        ));
      }
      this.expanded.push(value);
    },
    async updateStatusInReviewRequest(requestService) {
      let self = this;
      if (
        !self.requestingUserModule &&
        requestService.items.find(
          (item) =>
            item.status ==
            REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation
        )
      ) {
        clearTimeout(self.debounceRequests);
        self.debounceRequests = setTimeout(async () => {
          const resp = await self.sendUpdateStatusRequestToReview({
            request_id: requestService.id,
            user: self.user.email,
          });
        }, self.debounceTime);
      }
    },
    clearDateFilters() {
      this.filters.date_start = null;
      this.filters.date_end = null;
      this.getRequestServicesQuotesData(this.filters.page);
    },
    updateCurrentProject(project_code) {
      this.filters.project_code = project_code;
    },
    cleanCurrentProject() {
      this.filters.project_code = null;
    },
    updateCurrentCeco(ceco_code) {
      this.filters.ceco_code = ceco_code;
    },
    cleanCurrentCeco() {
      this.filters.ceco_code = null;
    },
    getStatusColor(status) {
      return REQUEST_SERVICE_CONSTANTS.getStatusColor(status);
    },
    getRequestServicesQuotesData(
      page = 1,
      update_current_service = false,
      updateTable = false
    ) {
      let self = this;
      clearTimeout(self.debounceQuotes);
      self.filters.page = page;
      self.debounceQuotes = setTimeout(async () => {
        self.loadingQuotes = true;
        await this.getRequestServicesQuotes({
          page: self.filters.page,
          general_filter: this.filters.filter ? this.filters.filter : undefined,
          project_code: this.filters.project_code
            ? this.filters.project_code.split(" - ")[0]
            : undefined,
          cost_center: this.filters.ceco_code
            ? this.filters.ceco_code.split(" - ")[0]
            : undefined,
          user: this.requestingUserModule ? this.user.email : undefined,
          business_unit: this.filters.business_unit
            ? this.filters.business_unit
            : undefined,
          since_date: this.filters.date_start
            ? `${this.filters.date_start}`
            : undefined,
          until_date: this.filters.date_end
            ? `${this.filters.date_end}`
            : undefined,
          status_filter: this.filters.status_filter
            ? this.filters.status_filter
            : undefined,
        });
        if (update_current_service) {
          setTimeout(() => {
            self.$refs.tableServicesDetail.updateFilesCurrentService();
          }, 800);
        }
        if (updateTable) {
          self.keyTableRequests = self.keyTableRequests + 1;
        }
        self.loadingQuotes = false;
      }, self.debounceTime);
    },
    requestIsStatusInPendingValidation(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.pending_validation
      );
    },
    requestIsStatusUserValidationVariant(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation_variant
      );
    },
    requestIsStatusInPurchaseValidation(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.purchase_validation
      );
    },
    requestIsStatusInPurchaseValidationVariant(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.purchase_validation_variant
      );
    },
    requestIsStatusInUserValidation(request) {
      return (
        request.status ===
        REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation
      );
    },
    formatAmount(amount, showDecimals = true) {
      if (showDecimals) {
        return new Intl.NumberFormat("es-Cl", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount.toString());
      } else {
        return new Intl.NumberFormat("es-Cl", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(amount.toString());
      }
    },
    createExchangeRateTable(items, totalPrice = 0) {
      // Create the table structure
      let table = `<table style='border: 1px solid #dededf; height: 100%;width: 100%;table-layout: fixed;border-collapse: collapse;border-spacing: 1px;text-align: left;color: #595959'>
          <thead>
            <tr>
              <th style='border: 1px solid #dededf;background-color: #2778C4;color: #FFFFFF;padding: 5px;'>Descripción</th>
              <th style='border: 1px solid #dededf;background-color: #2778C4;color: #FFFFFF;padding: 5px;'>Tasa de Cambio Utilizada</th>
              <th style='border: 1px solid #dededf;background-color: #2778C4;color: #FFFFFF;padding: 5px;'>Precio</th>
              <th style='border: 1px solid #dededf;background-color: #2778C4;color: #FFFFFF;padding: 5px;'>Monto en Pesos</th>
            </tr>
          </thead>
          <tbody>
        `;

      // Populate the table with items
      items.forEach((item) => {
        table += `
        <tr>
          <td style='border: 1px solid #dededf;background-color: #ffffff;padding: 5px;'>
            ${item.name}
          </td>
          <td style='border: 1px solid #dededf;background-color: #ffffff;padding: 5px;'>
            ${`${item.exchange_rate}`}
          </td>
          <td style='border: 1px solid #dededf;background-color: #ffffff;padding: 5px;'>
            ${item.unit_amount} ${item.coin}
          </td>
          <td style='border: 1px solid #dededf;background-color: #ffffff;padding: 5px;'>
            ${item.coin !== "CLP" ? `${item.amount} CLP` : "---"}
          </td>
        </tr>`;
      });

      // add total CLP
      table += `
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style='border: 1px solid #dededf;background-color: #2778C4;color: #FFFFFF;padding: 5px;'>${totalPrice} CLP</td>
        </tr>`;

      // Close the table
      table += "</tbody></table>";

      return table;
    },
    async resolveExchangeRateTable(items) {
      const coins = [
        ...new Set(
          items
            .filter((item) => item.coin && item.coin !== "CLP")
            .map((item) => item.coin)
        ),
      ];
      let exchangeRates = [];
      if (coins.length) {
        const promises = coins.map(async (item) => {
          return this.getExchangeRate({ to_currency: item });
        });
        exchangeRates = await Promise.all(promises);
      }

      let totalPrice = 0;
      const dataExchangeTable = items.map((item) => {
        if (item.coin && item.coin !== "CLP") {
          const exchange = exchangeRates.find(
            (exchange) => exchange.to_currency == item.coin
          );
          totalPrice += Math.round(item.unit_price * exchange.rate * 100) / 100;
          return {
            ...item,
            coin: item.coin ? item.coin : "CLP",
            unit_amount: this.formatAmount(
              item.unit_price,
              item.coin && item.coin !== "CLP"
            ),
            amount: this.formatAmount(
              Math.round(item.unit_price * exchange.rate * 100) / 100,
              false
            ),
            exchange_rate: `1 ${
              item.coin
            } ⟺ ${exchange.rate.toString().replaceAll(".", ",")} CLP`,
          };
        } else {
          totalPrice += item.unit_price;
          return {
            ...item,
            coin: "CLP",
            unit_amount: this.formatAmount(item.unit_price, false),
            amount: this.formatAmount(item.unit_price, false),
            exchange_rate: "---",
          };
        }
      });

      return this.createExchangeRateTable(dataExchangeTable, this.formatAmount(totalPrice, false));
    },
    async updateStatusRequest(requestService) {
      const exchangeRates = await this.resolveExchangeRateTable(
        requestService.items
      );

      if (
        !requestService.is_maverick &&
        requestService.items.find((item) => {
          return (
            REQUEST_SERVICE_CONSTANTS.getServiceQuotesPostService(item).length >
              1 && !item.documents.find((document) => document.selected)
          );
        })
      ) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Si hay mas de 1 cotización en un servicio, debes seleccionar 1 en la sección de documentos del servicio para continuar",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 7000,
          timerProgressBar: true,
        });
      }
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estás seguro de enviar esta solicitud con Cesta de Compra: <b>${requestService.order}</b> a la Matriz de Aprobación?`,
          footer: exchangeRates,
          width: "70%",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: `Enviar`,
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let resp = null;
            let payload = {
              comment: "Enviado a Matriz de Aprobación",
              request_id: requestService.id,
              user: this.user.email,
              send_to_approval: true,
            };
            if (requestService.is_maverick) {
              resp = await this.sendUpdateRequestMaverickToPendingApproval(
                payload
              );
            } else if (
              this.requestIsStatusInPurchaseValidationVariant(requestService)
            ) {
              resp = await this.sendUpdateStatusRequestToApproveFromPurchase(
                payload
              );
            } else {
              resp = await this.sendUpdateRequestToPendingApproval(payload);
            }
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud enviada correctamente a la matriz de aprobación, puede hacer seguimiento en la sección de aprobaciones`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getRequestServicesQuotesData();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusRequestToReviewUser(requestService) {
      const exchangeRates = await this.resolveExchangeRateTable(
        requestService.items
      );
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de enviar esta Solicitud con Cesta de Compra: <b>${requestService.order}</b> a Revisión por el Usuario?`,
          footer: exchangeRates,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#FF9900",
          confirmButtonText: `Enviar`,
          cancelButtonText: `Cancelar`,
          width: "70%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el usuario solicitante",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              request_id: requestService.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusRequestMaverickToReviewUser(
              payload
            );

            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud enviada correctamente a revisión por parte del usuario`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getRequestServicesQuotesData();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusRequestToQuotation(requestService) {
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de Solicitar una Recotización para la solicitud con Cesta de Compra: <b>${requestService.order}</b> ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#FF9900",
          confirmButtonText: `Solicitar`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el equipo de compras",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              request_id: requestService.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusRequestToQuotation(payload);

            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Recotización solicitada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getRequestServicesQuotesData(this.filters.page, false, true);
            }
            this.loading = false;
          }
        });
    },
    async updateStatusRequestToReviewPurchase(requestService) {
      const exchangeRates = await this.resolveExchangeRateTable(
        requestService.items
      );
      this.$swal
        .fire({
          icon: "info",
          title: `¿Estás seguro de Enviar esta Solicitud con Cesta de Compra: <b>${requestService.order}</b> a Revisión por Compras?`,
          footer: exchangeRates,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: `Enviar`,
          cancelButtonText: `Cancelar`,
          width: "70%",
          input: "textarea",
          inputPlaceholder: "Ingresa el feedback para el equipo de compras",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el feedback"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              request_id: requestService.id,
              user: this.user.email,
              comment: result.value,
            };
            const resp = await this.sendUpdateStatusRequestMaverickToReviewPurchase(
              payload
            );

            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud enviada correctamente a revisión por parte del equipo de compras`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getRequestServicesQuotesData();
            }
            this.loading = false;
          }
        });
    },
    async updateStatusRequestToRejection(requestService) {
      this.$swal
        .fire({
          icon: "error",
          title: `¿Estás seguro de Rechazar la Solicitud con Cesta de Compra: <b>${requestService.order}</b>?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#FF0000",
          confirmButtonText: `Rechazar`,
          cancelButtonText: `Cancelar`,
          width: "50%",
          input: "textarea",
          inputPlaceholder: "Ingresa el Motivo de Rechazo",
          preConfirm: (inputValue) => {
            if (!inputValue) {
              return this.$swal.showValidationMessage(
                "Por favor, ingresa el motivo de rechazo"
              );
            }
            return inputValue;
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let resp = null;
            let payload = {
              request_id: requestService.id,
              user: this.user.email,
              comment: result.value,
            };
            if (requestService.is_maverick) {
              resp = await this.sendUpdateStatusRequestMaverickToRejection(
                payload
              );
            } else {
              resp = await this.sendUpdateStatusRequestToRejection(payload);
            }

            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Solicitud Rechazada correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getRequestServicesQuotesData();
            }
            this.loading = false;
          }
        });
    },
    openModalCommentsRequest(item) {
      this.currentRequest = item;
      this.dialogCommmentsRequest = true;
    },
    closeModalCommentsRequest() {
      this.currentRequest = null;
      this.dialogCommmentsRequest = false;
    },
    openModalServicesDeleted(item) {
      this.currentRequest = item;
      this.dialogServicesDeleted = true;
    },
    closeModalServicesDeleted() {
      this.currentRequest = null;
      this.dialogServicesDeleted = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      requestServicesQuotes: "requestService/requestServicesQuotes",
      projects: "requestMaterialsSap/projects",
      cecos: "requestMaterialsSap/cecos",
      businessUnits: "requestMaterial/businessUnits",
    }),
    date_start_formatted: {
      get() {
        if (this.filters.date_start) {
          return moment(this.filters.date_start).format("DD/MM/YYYY");
        }
        return null;
      },
      set() {},
    },
    date_end_formatted: {
      get() {
        if (this.filters.date_end) {
          return moment(this.filters.date_end).format("DD/MM/YYYY");
        }
        return null;
      },
      set() {},
    },
    minDateEnd() {
      if (this.filters.date_start) {
        return this.filters.date_start;
      }
      return null;
    },
    maxDateStart() {
      if (this.filters.date_end) {
        return this.filters.date_end;
      }
      return null;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestServicesQuotesData(page);
    },
  },
};
</script>

<style>
.bg-maverick {
  background-color: #e3f2fd !important;
}
</style>
