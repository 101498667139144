<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1300px"
      scrollable
      @keydown="executeCloseTableAddMaterials"
      @click:outside="executeCloseTableAddMaterials"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Buscar {{ !stockItemGroup ? "Servicios" : "Materiales" }}</h5>
        </div>
        <hr />
        <v-card-text>
          <div class="card-body p-2 pb-0">
            <v-row align="start">
              <v-col cols="12" lg="12" md="12" sm="12" class="py-0">
                <div v-if="loadingSyncMaterials">
                  <span class="mx-2"> Ejecutando sincronización </span>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <v-btn
                  @click="sendSyncMaterials"
                  :disabled="loadingSyncMaterials"
                  color="primary"
                  :dark="false"
                  small
                  class="text-white mt-4"
                >
                  <i class="mdi mdi-sync me-1"></i>
                  Sincronizar {{ !stockItemGroup ? "Servicios" : "Materiales" }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="py-5">
                <v-text-field
                  v-model="filters.filter"
                  :disabled="loadingSyncMaterials"
                  @input="getMaterials(1)"
                  append-icon="mdi-magnify"
                  :label="`Buscar por Código o Descripción del ${
                    !stockItemGroup ? 'Servicio' : 'Material'
                  }`"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <div
                  class="d-flex align-items-center justify-content-end v-application"
                >
                  <v-checkbox
                    v-if="stockItemGroup"
                    :disabled="loadingSyncMaterials"
                    v-model="filters.favorite"
                    @click="getMaterials(1)"
                    class="mx-5 mt-4"
                  >
                    <template v-slot:label>
                      <h5 class="mt-3 mx-2 mb-0">
                        {{ !stockItemGroup ? "Servicios" : "Materiales" }}
                        Estandar
                      </h5>
                    </template>
                  </v-checkbox>
                  <h5 class="mb-0 mx-3">
                    Seleccionados: {{ selected.length }}
                  </h5>
                </div>
              </v-col>
            </v-row>
            <v-data-table
              v-model="selected"
              :headers="wrappedHeaders"
              :items="wrappedPaginatedMaterials"
              :single-select="false"
              item-key="code"
              show-select
              class="elevation-1 table-list-materiales mt-5"
              @input="selectProduct"
              :items-per-page="10"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingAllMaterials"
              loading-text="Cargando... Espere Por favor"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:item.favorite="{ item }">
                <v-icon color="primary" v-if="isFavorite(item)">
                  mdi-check-circle
                </v-icon>
                <span v-else></span>
              </template>
              <template v-slot:item.code="{ item }">
                <p class="my-0 text-left" style="font-size: 12px">
                  {{ item.code }}
                </p>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="my-0 text-left" style="font-size: 12px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.measurement_unit="{ item }">
                <p class="text-left ml-3 my-0" style="font-size: 11px">
                  {{ item.measurement_unit.name }}
                </p>
              </template>
              <template v-slot:item.price="{ item }">
                <p class="text-right my-0" style="font-size: 12px">
                  {{
                    `$ ${new Intl.NumberFormat("de-DE").format(
                      parseInt(item.price)
                    )}`
                  }}
                </p>
              </template>
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="1"
                  class="pt-0 mt-0"
                  @input="setMaterialQuantity($event, item)"
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).quantity !== undefined
                      ? materialIsSelected(item).quantity
                      : item.stock
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="d-flex justify-content-end pt-2 pb-1">
              <v-pagination
                v-model="filters.page"
                :disabled="loadingSyncMaterials"
                class="v-application"
                :length="
                  paginatedMaterials.total_pages
                    ? paginatedMaterials.total_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
            <div class="v-application">
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                outlined
                class="mr-3 mt-4"
                small
                @click="executeCloseTableAddMaterials"
                >Cancelar</v-btn
              >
              <v-btn
                :disabled="!checkedLength"
                type="submit"
                color="primary"
                class="mt-4"
                small
                @click="addMaterialsSelected"
                >Agregar Seleccionados</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";
import {
  CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
  TYPE_DESTINY_CECO_ID,
} from "@/constants/requestMaterial";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalTableAddMaterials: {
      type: Function,
      default: () => {},
    },
    /*Prop para solicitudes con grupo de articulos Existencia (Solicitud de Materiales) o 
    Solicitudes con grupo de articulos de NO Existencia (Solicitud de Servicios)*/
    stockItemGroup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
      debounce: null,
      debounceTime: 500,
      selected: [],
      typeDestiny: null,
      filters: {
        page: 1,
        items: 10,
        favorite: this.stockItemGroup ? true : false,
        filter: "",
      },
      checkedLength: false,
      headers: [
        {
          text: "STD",
          align: "center",
          width: "5%",
          sortable: false,
          value: "favorite",
        },
        {
          text: "Código",
          value: "code",
          sortable: true,
        },
        {
          text: "Descripción",
          value: "name",
        },
      ],
      materialsSelected: [],
      maxProducts: 200,
    };
  },
  mounted() {
    if (this.$route.query.sap_project) {
      this.getMaterials(1);
    }
  },
  methods: {
    ...mapActions({
      getAllMaterials: "requestMaterialsSap/getAllMaterials",
      getAllMaterialsCostCenter:
        "requestMaterialsSap/getAllMaterialsCostCenter",
      syncAllMaterials: "requestMaterialsSap/syncAllMaterials",
      clearMaterials: "requestMaterialsSap/clearMaterials",
    }),
    updateTypeDestiny(typeDestiny) {
      this.typeDestiny = typeDestiny;
    },
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.code === material.code);
    },
    setMaterialQuantity(value, material) {
      this.materialsSelected.forEach((item) => {
        if (item.code === material.code) {
          item.quantity =
            value >= 0 ? Math.trunc(value) : this.stockItemGroup ? 0 : 1;
        }
      });
      this.materialsSelected = [...this.materialsSelected];
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? items : [item];
        materials.forEach((material) => {
          this.materialsSelected.push(material);
          this.setMaterialQuantity(this.stockItemGroup ? 0 : 1, material);
        });
      }
    },
    async getMaterials(page = 1, ceco = null) {
      if (!this.loadingSyncMaterials) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function () {
          self.filters.page = page;
          if (self.typeDestiny || self.$route.query.sap_project) {
            if (self.typeDestiny == TYPE_DESTINY_CECO_ID) {
              await self.getAllMaterialsCostCenter({
                ...self.filters,
                filter: self.filters.filter ? self.filters.filter : undefined,
                business_unit: ceco ? ceco : undefined,
              });
            } else {
              await self.getAllMaterials({
                ...self.filters,
                is_service: !self.stockItemGroup ? true : false,
                business_unit: self.projectInfo?.business_unit
                  ? self.projectInfo.business_unit.sap_code
                  : undefined,
              });
            }
          } else {
            self.clearMaterials();
          }
        }, self.debounceTime);
      }
    },
    async sendSyncMaterials() {
      this.$swal({
        icon: "info",
        title: "Atención",
        text: "La sincronización puede tardar unos minutos, una vez sincronizado el botón se habilitara nuevamente",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 6000,
        timerProgressBar: true,
      });
      const response = await this.syncAllMaterials();
      if (response.status == 500) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intenta de nuevo mas tarde",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 200) {
        await this.getMaterials();
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: `Listado de ${
            !this.stockItemGroup ? "servicios" : "materiales"
          } sincronizados con éxito`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    addMaterialsSelected() {
      if (this.materialsSelected.length > this.maxProducts) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `Solo puede seleccionar hasta ${this.maxProducts} ${
            !this.stockItemGroup ? "servicios" : "materiales"
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.stockItemGroup) {
        this.materialsSelected.forEach((material, indexMaterial) => {
          if (!REQUEST_SERVICE_CONSTANTS.isServiceIsAnAsset(material)) {
            if (material.quantity > 1) {
              this.materialsSelected = this.materialsSelected.filter((item) => {
                return item.code !== material.code;
              });
              for (let index = 0; index < material.quantity; index++) {
                let id = new Date().getTime() + indexMaterial + index;
                this.materialsSelected.push({
                  ...material,
                  quantity: 1,
                  id,
                });
              }
            }
          }
        });
        this.materialsSelected.sort((a, b) => a.code.localeCompare(b.code));
      }
      this.$emit("addMaterialsSelected", this.materialsSelected);
      this.materialsSelected = [];
      this.selected = [];
      this.closeModalTableAddMaterials();
    },
    selectProduct(producto) {
      if (this.selected.length > this.maxProducts) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `Solo puede seleccionar hasta ${this.maxProducts} ${
            !this.stockItemGroup ? "servicios" : "materiales"
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.selected.forEach((element) => {
        element.unit = element.measurement_unit.name;
      });
      this.materialsSelected = this.selected;
      if (this.selected.length > 0) {
        this.checkedLength = true;
      } else {
        this.checkedLength = false;
      }
    },
    executeCloseTableAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalTableAddMaterials($event);
      }
    },
    resetFilter() {
      this.filters.filter = "";
      this.filters.favorite = false;
      this.getMaterials(1);
    },
    isFavorite(item) {
      return (
        item.favorite ||
        item.favorite_ci ||
        item.favorite_otros ||
        item.favorite_pg ||
        item.favorite_re
      );
    },
  },
  computed: {
    ...mapGetters({
      paginatedMaterials: "requestMaterialsSap/allMaterials",
      loadingAllMaterials: "requestMaterialsSap/loadingMaterials",
      loadingSyncMaterials: "requestMaterialsSap/loadingSyncMaterials",
      projectInfo: "project/projectInfo",
    }),
    wrappedPaginatedMaterials() {
      return this.paginatedMaterials.items ? this.paginatedMaterials.items : [];
    },
    wrappedHeaders() {
      if (this.stockItemGroup) {
        return [
          ...this.headers,
          {
            text: "Stock",
            value: "stock",
            width: "10%",
            sortable: true,
          },
          {
            text: "Monto Unitario",
            value: "price",
            width: "10%",
            align: "right",
            sortable: true,
          },
          {
            text: "Cantidad a Solicitar",
            align: "center",
            width: "10%",
            sortable: false,
            value: "quantity",
          },
        ];
      }
      return [
        ...this.headers,
        {
          text: "Cantidad a Solicitar",
          align: "center",
          width: "10%",
          sortable: false,
          value: "quantity",
        },
      ];
    },
    selectedRows() {
      return this.items.filter((item) => item.selected);
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getMaterials(page);
    },
  },
};
</script>

<style></style>
