<template>
  <v-dialog
    v-model="modalAction"
    max-width="1000"
    scrollable
    @keydown="executeModalServicesDeleted"
    @click:outside="executeModalServicesDeleted"
  >
    <v-card>
      <div class="p-4 pb-0" v-if="currentRequest">
        <h5>
          Servicios Eliminados de Solicitud con Cesta de Compra:
          <b>{{ currentRequest.order }}</b>
        </h5>
      </div>
      <hr />
      <v-card-text>
        <section class="my-5 px-3" v-if="currentRequest">
          <v-simple-table class="elevation-3">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Código de SAP</th>
                  <th class="text-left">Descripción</th>
                  <th class="text-right">Cantidad solicitada</th>
                  <th class="text-center">Estatus</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(service, index) in servicesDeleted"
                  :key="service.id"
                >
                  <td>
                    <p class="my-0 text-left">
                      {{ service.json_data.sap_code }}
                    </p>
                  </td>
                  <td>
                    <p class="text-left my-0">
                      {{
                        service.json_data.name ? service.json_data.name : "-"
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="text-right my-0">
                      {{ service.json_data.quantity }}
                    </p>
                  </td>
                  <td>
                    <v-chip
                      label
                      small
                      color="red darken-2"
                      class="mt-2 text-white"
                    >
                      Eliminado
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="servicesDeleted.length == 0">
                  <td colspan="10" class="bg-white">
                    <small>
                      Sin Servicios Eliminados
                    </small>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </section>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          outlined
          @click="executeModalServicesDeleted"
        >
          <small>
            Cerrar
          </small>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { mapActions } from "vuex";
import TableServicesDetail from "./TableServicesDetail.vue";

export default {
  components: { TableServicesDetail },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalServicesDeleted: {
      type: Function,
      default: () => {},
    },
    currentRequest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      servicesDeleted: [],
    };
  },
  methods: {
    ...mapActions({
      getServicesDeleted: "requestService/getServicesDeleted",
    }),
    executeModalServicesDeleted($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalServicesDeleted($event);
      }
    },
  },
  watch: {
    currentRequest: async function(newValue) {
      if (newValue) {
        const data = await this.getServicesDeleted({ request_id: newValue.id });
        this.servicesDeleted = data;
      } else {
        this.servicesDeleted = [];
      }
    },
  },
};
</script>

<style></style>
