var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-3 mt-5"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('header',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1",attrs:{"align-content":"start","align-md":"start"}},[_vm._t("actions"),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"clearable":"","placeholder":"Buscar:"},on:{"input":function($event){return _vm.getRequestServicesQuotesData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.REQUEST_SERVICE_CONSTANTS.service_statuses_list().map(function (item) { return ({
            name: _vm.getStatusCorrection(item),
            value: item,
          }); }),"item-text":"name","item-value":"value","label":"Filtrar por Estatus"},on:{"input":function($event){return _vm.getRequestServicesQuotesData(1)}},model:{value:(_vm.filters.status_filter),callback:function ($$v) {_vm.$set(_vm.filters, "status_filter", $$v)},expression:"filters.status_filter"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.businessUnits,"item-text":"name","item-value":"name","no-data-text":"Sin Unidades de Negocio","label":"Filtrar por Unidad de Negocio"},on:{"input":function($event){return _vm.getRequestServicesQuotesData(1)}},model:{value:(_vm.filters.business_unit),callback:function ($$v) {_vm.$set(_vm.filters, "business_unit", $$v)},expression:"filters.business_unit"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('filter-project',{ref:"filterProject",attrs:{"projects":_vm.projects.items
            ? _vm.projects.items.filter(function (item) { return item.warehouse; })
            : [],"getProjectsFunction":_vm.getProjects},on:{"cleanProject":_vm.cleanCurrentProject,"inputProject":function($event){_vm.updateCurrentProject($event);
          _vm.getRequestServicesQuotesData(1);}}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('filter-ceco',{attrs:{"cecos":_vm.cecos.items ? _vm.cecos.items : [],"getCecosFunction":_vm.getCecos},on:{"cleanCeco":_vm.cleanCurrentCeco,"inputCeco":function($event){_vm.updateCurrentCeco($event);
          _vm.getRequestServicesQuotesData(1);}}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","label":"Filtrar por Fecha (Desde)","clearable":""},on:{"click:clear":_vm.clearDateFilters},model:{value:(_vm.date_start_formatted),callback:function ($$v) {_vm.date_start_formatted=$$v},expression:"date_start_formatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_start),callback:function ($$v) {_vm.datepicker_menu_start=$$v},expression:"datepicker_menu_start"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es","max":_vm.maxDateStart},on:{"input":function($event){_vm.datepicker_menu_start = false;
            _vm.getRequestServicesQuotesData(1);}},model:{value:(_vm.filters.date_start),callback:function ($$v) {_vm.$set(_vm.filters, "date_start", $$v)},expression:"filters.date_start"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","label":"Filtrar por Fecha (Hasta)","clearable":""},on:{"click:clear":_vm.clearDateFilters},model:{value:(_vm.date_end_formatted),callback:function ($$v) {_vm.date_end_formatted=$$v},expression:"date_end_formatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_menu_end),callback:function ($$v) {_vm.datepicker_menu_end=$$v},expression:"datepicker_menu_end"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es","clearable":"","min":_vm.minDateEnd},on:{"input":function($event){_vm.datepicker_menu_end = false;
            _vm.getRequestServicesQuotesData(1);}},model:{value:(_vm.filters.date_end),callback:function ($$v) {_vm.$set(_vm.filters, "date_end", $$v)},expression:"filters.date_end"}})],1)],1)],2),_c('v-data-table',{key:_vm.keyTableRequests,staticClass:"px-1 elevation-3 mt-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.requestServicesQuotes.items,"items-per-page":10,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loadingQuotes,"loading-text":"Cargando Cotizaciones","item-key":"id","show-expand":"","expanded":_vm.expanded,"no-data-text":"Sin Cotizaciones"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var isExpanded = ref.isExpanded;
return [_c('tr',{class:_vm.rowColor(item),on:{"click":function($event){_vm.clickedRow(item);
          _vm.updateStatusInReviewRequest(item);}}},[_c('td',[_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"blue darken-3",attrs:{"fab":"","x-small":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":""}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                  (_vm.requestIsStatusUserValidationVariant(item) &&
                    _vm.requestingUserModule &&
                    !item.is_maverick) ||
                    (_vm.requestIsStatusInPurchaseValidation(item) &&
                      !_vm.requestingUserModule &&
                      item.is_maverick) ||
                    (_vm.requestIsStatusInPurchaseValidationVariant(item) &&
                      !_vm.requestingUserModule &&
                      !item.is_maverick)
                ),expression:"\n                  (requestIsStatusUserValidationVariant(item) &&\n                    requestingUserModule &&\n                    !item.is_maverick) ||\n                    (requestIsStatusInPurchaseValidation(item) &&\n                      !requestingUserModule &&\n                      item.is_maverick) ||\n                    (requestIsStatusInPurchaseValidationVariant(item) &&\n                      !requestingUserModule &&\n                      !item.is_maverick)\n                "}],staticClass:"mb-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusRequest(item)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" 👌 ")]),_vm._v(" Enviar Solicitud a Matriz de Aprobación ")])],1),(
                  !item.is_maverick &&
                    _vm.requestIsStatusUserValidationVariant(item) &&
                    _vm.requestingUserModule
                )?_c('v-list-item',{staticClass:"mb-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusRequestToQuotation(item)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" ⚠️ ")]),_vm._v(" Solicitar Recotización de Todos los Servicios ")])],1):_vm._e(),(
                  _vm.requestIsStatusInPurchaseValidation(item) &&
                    !_vm.requestingUserModule &&
                    item.is_maverick
                )?_c('v-list-item',{staticClass:"mb-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusRequestToReviewUser(item)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" ⚠️ ")]),_vm._v(" Enviar Solicitud a Revisión por el Usuario ")])],1):_vm._e(),(
                  _vm.requestIsStatusInUserValidation(item) &&
                    _vm.requestingUserModule &&
                    item.is_maverick
                )?_c('v-list-item',{staticClass:"mb-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusRequestToReviewPurchase(item)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" 👌 ")]),_c('span',[_vm._v(" Enviar Solicitud a Revisión por Compras ")])])],1):_vm._e(),(
                  (_vm.requestIsStatusInUserValidation(item) &&
                    item.is_maverick &&
                    (_vm.requestingUserModule ||
                      _vm.REQUEST_SERVICE_CONSTANTS.isRequestCancelationHas14DaysDiffFromToday(
                        item
                      ))) ||
                    (!item.is_maverick &&
                      _vm.requestingUserModule &&
                      _vm.requestIsStatusUserValidationVariant(item))
                )?_c('v-list-item',{staticClass:"mb-0 pl-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusRequestToRejection(item)}}},[_c('v-icon',{staticClass:"text-danger mx-1"},[_vm._v(" mdi-close-circle-outline ")]),_c('span',[_vm._v(" Rechazar Solicitud ")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"mb-0 mt-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",staticStyle:{"margin-top":"-1rem"},attrs:{"x-small":""},on:{"click":function($event){return _vm.openModalCommentsRequest(item)}}},[_c('v-badge',{attrs:{"content":item.comments && item.comments.length
                        ? item.comments.length
                        : '0',"color":item.comments && item.comments.length
                        ? 'success'
                        : 'red',"overlap":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem","margin-top":"-2px"},attrs:{"color":"primary"}},[_vm._v(" mdi-comment-outline ")])],1),_c('span',{staticClass:"mx-2"},[_vm._v(" Comentarios ")])],1)],1),(!item.is_maverick)?_c('v-list-item',{staticClass:"mb-0 pl-1"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.openModalServicesDeleted(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v(" mdi-delete-outline ")]),_vm._v(" Ver Servicios Eliminados ")],1)],1):_vm._e()],1)],1)],1),_c('td',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.order)+" ")])]),_c('td',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.project ? item.project.code : item.cost_center.code)+" ")])]),_c('td',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.sap_stage ? item.sap_stage : "Sin Etapa")+" ")])]),_c('td',[_c('span',{staticClass:"mt-2",class:{
              'text-primary font-weight-bold': item.is_maverick,
            },staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(!item.request_type ? "-" : item.request_type == _vm.REQUEST_MATERIAL ? "SS" : item.is_maverick ? "MAVERICK" : "SSU")+" ")])]),_c('td',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.created_by)+" ")])]),_c('td',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]),_c('td',[(
              item.approval_request &&
                (item.approval_request.cancelled ||
                  item.approval_request.rejected ||
                  item.approval_request.sent_to_erp)
            )?_c('v-chip',{staticClass:"mt-2 text-white",attrs:{"label":"","small":"","color":_vm.getStatusColorApprovalRequest(item.approval_request)}},[_vm._v(" "+_vm._s(item.approval_request.cancelled ? "Anulada" : item.approval_request.rejected ? "Rechazada" : item.approval_request.sent_to_erp ? "Aprobada" : "Pendiente")+" ")]):_c('v-chip',{staticClass:"mt-2 text-white",attrs:{"label":"","small":"","color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusCorrection(item.status))+" ")])],1),_c('td',[_c('v-icon',[_vm._v(" "+_vm._s(isExpanded ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{staticClass:"bg-gray",attrs:{"colspan":headers.length}},[_c('section',{staticClass:"my-5 px-3"},[_c('table-services-detail',{ref:"tableServicesDetail",staticClass:"mt-5",attrs:{"requestingUserModule":_vm.requestingUserModule,"request-service":item},on:{"getRequestServicesAndUpdateTable":function($event){return _vm.getRequestServicesQuotesData(_vm.filters.page, false, true)},"getRequestsService":function($event){return _vm.getRequestServicesQuotesData(_vm.filters.page)},"getRequestsServiceAndUpdateCurrentService":function($event){return _vm.getRequestServicesQuotesData(_vm.filters.page, true, false)}}})],1)])]}}])}),_c('div',{staticClass:"my-3 float-right"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.requestServicesQuotes.total_pages
          ? _vm.requestServicesQuotes.total_pages
          : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('modal-comments-service',{attrs:{"modalAction":_vm.dialogCommmentsRequest,"closeModalComments":_vm.closeModalCommentsRequest,"currentRequest":_vm.currentRequest},on:{"commentAdded":function($event){return _vm.getRequestServicesQuotesData(_vm.filters.page)}}}),_c('modal-services-deleted',{attrs:{"modalAction":_vm.dialogServicesDeleted,"closeModalServicesDeleted":_vm.closeModalServicesDeleted,"currentRequest":_vm.currentRequest}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }