<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1300px"
      scrollable
      @keydown="executeCloseModalCreateRequestMaterial"
      @click:outside="executeCloseModalCreateRequestMaterial"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            Agregar Solicitud
            {{ !stockItemGroup ? "de Servicios" : "de Materiales" }}
          </h5>
        </div>
        <hr />
        <v-spacer></v-spacer>
        <v-card-text>
          <v-container>
            <v-row align-content="center">
              <v-col cols="12" md="6" sm="12">
                <label
                  class="font-weight-bold"
                  v-if="!$route.query.sap_project && !stockItemGroup"
                >
                  Destino
                </label>
                <v-select
                  v-if="!$route.query.sap_project && !stockItemGroup"
                  v-model="form.type_destiny"
                  :items="TYPES_DESTINY_REQUEST_MATERIALS"
                  item-text="name"
                  item-value="id"
                  @input="getMaterialsFromTypeDestiny()"
                ></v-select>
                <div
                  class="form-ceco mt-3"
                  v-if="form.type_destiny == TYPE_DESTINY_CECO_ID"
                >
                  <label class="font-weight-bold"> CECO </label>
                  <v-autocomplete
                    v-model="form.ceco"
                    :items="cecos.items ? cecos.items : []"
                    item-text="name_code"
                    @input="changeCeco"
                    @update:search-input="getCecosData"
                    @click:clear="getCecosData"
                    no-data-text="Sin CECOS"
                    :loading="loadingCecos"
                  ></v-autocomplete>
                </div>
                <div class="form_project">
                  <label
                    class="font-weight-bold"
                    :class="{
                      'mt-3': !stockItemGroup,
                    }"
                    v-if="
                      form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                      stockItemGroup
                    "
                  >
                    Proyecto
                  </label>
                  <v-autocomplete
                    v-if="
                      form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                      stockItemGroup
                    "
                    v-model="form.project"
                    clearable
                    :items="
                      projects.items
                        ? projects.items.filter((item) => item.warehouse)
                        : []
                    "
                    item-text="name_code"
                    @input="changeProject()"
                    @update:search-input="getProjectsData"
                    @click:clear="getProjectsData"
                    no-data-text="Sin proyectos"
                    :loading="loadingProjects"
                  ></v-autocomplete>
                  <section
                    v-if="
                      ((form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                        stockItemGroup) &&
                        form.project &&
                        !loadingProjects &&
                        getCurrentProjectFromCode) ||
                      ($route.query.sap_project && getCurrentProjectFromCode)
                    "
                  >
                    <label class="font-weight-bold"> Etapa del proyecto </label>
                    <v-autocomplete
                      v-model="form.sap_stage"
                      :items="availableStagesProjects"
                      :disabled="
                        !availableStagesProjects ||
                        !availableStagesProjects.length ||
                        statusAvailableProjects === 404
                      "
                      item-value="sap_code"
                      item-text="name"
                    >
                    </v-autocomplete>

                    <p
                      class="text-danger"
                      v-if="
                        !availableStagesProjects ||
                        !availableStagesProjects.length ||
                        statusAvailableProjects === 404
                      "
                    >
                      <b>Este proyecto no tiene Etapas de Proyecto Activas</b>
                    </p>

                    <label class="font-weight-bold"> Unidad de Negocio </label>
                    <p style="font-size: 12px">
                      {{ getCurrentProjectFromCode.business_unit.name }}
                    </p>
                    <label class="font-weight-bold"> Tipo de Venta </label>
                    <p style="font-size: 12px">
                      {{ getCurrentProjectFromCode.sale_type }}
                    </p>
                  </section>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <label class="font-weight-bold">
                  Tipo de Solicitud
                  {{ !stockItemGroup ? "de Servicios" : "de Materiales" }}
                </label>
                <v-select
                  clearable
                  v-model="form.request_type"
                  :items="typeRequests.map((item) => item.name)"
                ></v-select>
                <p class="text-warning">
                  <b>IMPORTANTE</b>: En este selector puedes cambiar el Tipo de
                  Solicitud
                  {{ !stockItemGroup ? "de Servicios" : "de Materiales" }} que
                  deseas crear.
                </p>
              </v-col>
            </v-row>
            <div class="d-flex justify-content-between align-items-end">
              <div class="w-50">
                <p
                  v-if="
                    !stockItemGroup &&
                    form.request_type == REQUEST_SERVICE_MAVERICK
                  "
                  class="text-info"
                >
                  <b class="text-info"> ATENCIÓN: </b>
                  <br />
                  Los Servicios de <b>Solicitudes Maverick</b> deben tener
                  <b>1 Cotización con Precio y Proveedor definidos. </b>
                </p>
              </div>
              <div
                class="d-flex flex-column justify-content-center align-items-end"
              >
                <div>
                  <v-btn
                    small
                    :disabled="
                      !form.type_destiny &&
                      !$route.query.sap_project &&
                      !stockItemGroup
                    "
                    color="primary text-white"
                    class=""
                    @click="openModalTableAddMaterials"
                  >
                    Agregar {{ !stockItemGroup ? "Servicios" : "Materiales" }}
                  </v-btn>
                </div>
                <small
                  v-if="
                    !form.type_destiny &&
                    !$route.query.sap_project &&
                    !stockItemGroup
                  "
                  class="text-warning mt-2 text-uppercase"
                >
                  Selecciona el destino de la solicitud para agregar los
                  {{ !stockItemGroup ? "servicios" : "materiales" }}
                </small>
              </div>
            </div>
            <v-data-table
              class="px-2 elevation-4 mt-5 pt-2"
              :headers="wrappedTableHeaders"
              :items="currentMaterials"
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              loading-text="Cargando Materiales"
              item-key="key"
              no-data-text="Sin Materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-if="
                    stockItemGroup ||
                    REQUEST_SERVICE_CONSTANTS.isServiceIsAnAsset(item)
                  "
                  type="number"
                  :min="1"
                  class="pt-0 mt-0"
                  v-model="item.quantity"
                  :hide-details="item.quantity < 1 ? false : true"
                  :error="item.quantity < 1"
                  :error-messages="item.quantity < 1 ? '1 unidad mínima' : null"
                ></v-text-field>
                <span v-else> N/A </span>
              </template>
              <template v-slot:item.code="{ item }">
                <p class="my-0 text-left" style="font-size: 12px">
                  {{ item.code }}
                </p>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="my-0 text-left" style="font-size: 12px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.price="{ item }">
                <p class="my-0" style="font-size: 12px">
                  {{
                    `$ ${new Intl.NumberFormat("de-DE").format(
                      parseInt(item.price_reference)
                    )}`
                  }}
                </p>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-content-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="deleteMaterial(item)"
                        icon
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-delete-outline </v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Eliminar
                      {{ !stockItemGroup ? "Servicio" : "Material" }}</span
                    >
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="!stockItemGroup"
                        @click="openModalFilesService(item)"
                        icon
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-badge
                          :content="
                            item.documents.length ? item.documents.length : '0'
                          "
                          :color="
                            !item.documents.length ||
                            (form.request_type == REQUEST_SERVICE_MAVERICK &&
                              !REQUEST_SERVICE_CONSTANTS.ifServiceHasQuotedWithPriceAndSupplierAndHaveMaverickLetter(
                                item
                              ))
                              ? 'red'
                              : 'success'
                          "
                          overlap
                        >
                          <v-icon> mdi-file-outline </v-icon>
                        </v-badge>
                      </v-btn>
                    </template>
                    <span> Documentos del Servicio </span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="!stockItemGroup"
                        @click="openModalUpdateCommentService(item)"
                        icon
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                      >
                        <v-badge
                          :content="item.comment ? 1 : '0'"
                          :color="item.comment ? 'success' : 'red'"
                          overlap
                        >
                          <v-icon> mdi-comment-outline </v-icon>
                        </v-badge>
                      </v-btn>
                    </template>
                    <span> Comentarios del Servicio </span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="mt-5 pt-5"
              v-if="stockItemGroup"
            >
              <label for="comments" class="mt-4"> Comentario solicitud </label>
              <v-textarea
                rows="4"
                counter
                outlined
                v-model="form.description"
              ></v-textarea>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color=""
            @click="executeCloseModalCreateRequestMaterial"
          >
            <small> Cerrar </small>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="primary"
            class="text-white"
            @click="
              !stockItemGroup
                ? createRequestService()
                : createRequestMaterials(true)
            "
          >
            <small> Guardar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <table-add-materials
      ref="tableAddMaterials"
      :modalAction="dialogTableAddMaterials"
      :closeModalTableAddMaterials="closeModalTableAddMaterials"
      :stockItemGroup="stockItemGroup"
      @addMaterialsSelected="addMaterialsSelected"
    ></table-add-materials>
    <modal-files-service
      :modalAction="dialogModalFilesService"
      :closeModalFilesService="closeModalFilesService"
      :currentService="currrentService"
      :typeRequestService="form.request_type"
    >
    </modal-files-service>
    <modal-update-comment-service
      :modalAction="dialogModalUpdateCommentService"
      :closeModalUpdateCommentService="closeModalUpdateCommentService"
      :currentService="currrentService"
    >
    </modal-update-comment-service>
  </section>
</template>

<script>
import swal2 from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import {
  preventCloseIfNotKeyEventEsc,
  renameAndReturnNewFile,
} from "@/helpers/common";
import {
  TYPE_REQUESTS,
  TYPE_REQUESTS_SERVICE,
  MAP_REQUEST_SERVICE_FROM_MATERIAL,
  TYPE_REQUESTS_MATERIALS,
  TYPES_DESTINY_REQUEST_MATERIALS,
  TYPE_DESTINY_PROJECT_ID,
  TYPE_DESTINY_CECO_ID,
  REQUEST_SERVICE_MAVERICK,
} from "@/constants/requestMaterial";
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";
import TableAddMaterials from "./TableAddMaterials.vue";
import ModalFilesService from "../../requestServices/components/ModalFilesService.vue";
import ModalUpdateCommentService from "../../requestServices/components/ModalUpdateCommentService.vue";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateRequestMaterials: {
      type: Function,
      default: () => {},
    },
    //Prop para solicitudes de bodega móvil o solicitudes de bodegas standard
    typeRequest: {
      type: Number,
      default: TYPE_REQUESTS_MATERIALS.standard,
    },
    /*Prop para solicitudes con grupo de articulos Existencia (Solicitud de Materiales) o
    Solicitudes con grupo de articulos de NO Existencia (Solicitud de Servicios)*/
    stockItemGroup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TableAddMaterials,
    ModalFilesService,
    ModalUpdateCommentService,
  },
  data() {
    return {
      REQUEST_SERVICE_CONSTANTS,
      REQUEST_SERVICE_MAVERICK,
      TYPES_DESTINY_REQUEST_MATERIALS,
      TYPE_DESTINY_PROJECT_ID,
      TYPE_DESTINY_CECO_ID,
      typeRequests: !this.stockItemGroup
        ? TYPE_REQUESTS_SERVICE
        : TYPE_REQUESTS,
      dialogTableAddMaterials: false,
      dialogModalFilesService: false,
      dialogModalUpdateCommentService: false,
      loading: false,
      loadingProjects: false,
      loadingCecos: false,
      debounce: null,
      debounceCecos: null,
      debounceTime: 500,
      currentMaterials: [],
      currrentService: null,
      filters: {
        page: 1,
        items: 50,
        filter: "",
      },
      form: {
        request_type: null,
        type_destiny: null,
        description: "",
        project: null,
        ceco: null,
        sap_stage: null,
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          width: "5%",
          sortable: false,
          value: "actions",
        },
        {
          text: "Código",
          align: "center",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: !this.stockItemGroup ? "Servicio" : "Producto",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          width: "5%",
          value: "unit",
        },
      ],
      statusAvailableProjects: null,
    };
  },
  mounted() {
    if (this.$route.query.sap_project) {
      this.getProjectsData(this.$route.query.sap_project);
    }
    if (this.stockItemGroup) {
      this.form.type_destiny = TYPE_DESTINY_PROJECT_ID;
      this.getMaterialsFromTypeDestiny();
    }
  },
  methods: {
    ...mapActions({
      createRequestApproveMaterials:
        "requestMaterial/createRequestApproveMaterials",
      sendCreateRequestService: "requestMaterialsSap/createRequestService",
      getProjects: "requestMaterialsSap/getProjects",
      getCecos: "requestMaterialsSap/getCecos",
      clearAvailableStages: "requestMaterialsSap/clearAvailableStages",
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
      getProjectInfo: "project/getProjectInfo",
    }),
    async getMaterialsFromTypeDestiny() {
      this.currentMaterials = [];
      this.$refs.tableAddMaterials.updateTypeDestiny(this.form.type_destiny);
    },
    async changeCeco(ceco) {
      if (ceco) {
        this.$refs.tableAddMaterials.getMaterials(1, ceco.split("-")[0]);
      }
    },
    async changeProject() {
      await this.getProjectInfo({
        project_code: this.$route.query.sap_project
          ? this.$route.query.sap_project
          : this.form.project?.split(" - ")[0],
      });
      this.$refs.tableAddMaterials.getMaterials();
      await this.getStagesFromProject();
    },
    async getStagesFromProject() {
      if (this.form.project || this.$route.query.sap_project) {
        const res = await this.getStagesAvailableFromProject({
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.form.project?.split(" - ")[0],
        });
        this.statusAvailableProjects = res.status;
        this.form.sap_stage = null;
      } else {
        this.form.sap_stage = null;
        this.clearAvailableStages();
      }
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.project !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounce);
        let self = this;
        this.debounceProjects = setTimeout(async function () {
          await self.getProjects({
            is_mobile:
              self.typeRequest == TYPE_REQUESTS_MATERIALS.mobile_warehouses
                ? true
                : undefined,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    async getCecosData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.ceco !== search)
      ) {
        this.loadingCecos = true;
        clearTimeout(this.debounceCecos);
        let self = this;
        this.debounceCecos = setTimeout(async function () {
          await self.getCecos({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingCecos = false;
        }, self.debounceTime);
      }
    },
    async createRequestService() {
      if (
        !this.form.type_destiny ||
        this.currentMaterials.length == 0 ||
        !this.form.request_type
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Destino, los servicios y el tipo de solicitud son requeridos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (
        this.form.type_destiny == TYPE_DESTINY_PROJECT_ID &&
        (!this.form.project || !this.form.sap_stage)
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Proyecto y etapa son requeridos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (this.form.type_destiny == TYPE_DESTINY_CECO_ID && !this.form.ceco) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El CECO es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (
        this.form.request_type == REQUEST_SERVICE_MAVERICK &&
        this.currentMaterials.find((item) => {
          return !REQUEST_SERVICE_CONSTANTS.ifServiceHasQuotedWithPriceAndSupplierAndHaveMaverickLetter(
            item
          );
        })
      ) {
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: "En una Solicitud de Compra Maverick, debe haber al menos 1 cotización con precio y proveedor definidos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 8000,
          timerProgressBar: true,
        });
      }
      if (
        this.currentMaterials.find((item) => {
          return REQUEST_SERVICE_CONSTANTS.ifServiceHasQuotedWithNoPriceOrSupplier(
            item
          );
        })
      ) {
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: "Todas las cotizaciones adjuntas deben tener un precio y proveedor definidos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }

      if (
        this.currentMaterials.find((item) => {
          return REQUEST_SERVICE_CONSTANTS.ifServiceHasNoComment(item);
        })
      ) {
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: "Todos los servicios deben tener un comentario",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }

      if (
        this.currentMaterials.find((item) => {
          return REQUEST_SERVICE_CONSTANTS.ifServiceHasMoreThanOneQuoteAndNoOneIsSelected(
            item
          );
        })
      ) {
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: "Si hay mas de una cotización en un servicio, debes seleccionar una para continuar",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }

      this.loading = true;
      let existDocumentDuplicatesInService = false;
      let filesRequestService = [];
      let self = this;
      let payload = {
        body: {
          project_code:
            this.form.type_destiny == TYPE_DESTINY_CECO_ID
              ? null
              : this.form.project.split(" - ")[0],
          cost_center_code:
            this.form.type_destiny == TYPE_DESTINY_CECO_ID
              ? this.form.ceco.split(" - ")[0]
              : null,
          request_type:
            MAP_REQUEST_SERVICE_FROM_MATERIAL[this.form.request_type],
          sap_stage:
            this.form.type_destiny == TYPE_DESTINY_PROJECT_ID
              ? this.form.sap_stage
              : undefined,

          created_by: this.user.email,
          comments: this.form.description,
          is_maverick:
            this.form.request_type == REQUEST_SERVICE_MAVERICK ? true : false,
          materials: this.currentMaterials.reduce(
            (finalObject, currentObject) => {
              let filesService = {};
              // Validar que el array de filesRequest no tenga documentos con el mismo nombre
              let filesRequestNames = currentObject.documents.map(
                ({ file }) => file.name
              );
              let filesNameDuplicates = filesRequestNames.filter(
                (value, index, self) => {
                  return self.indexOf(value) !== index;
                }
              );
              if (filesNameDuplicates.length > 0) {
                existDocumentDuplicatesInService = true;
              }
              currentObject.documents.forEach((document, index) => {
                let filename = `${currentObject.code}_${
                  new Date().getTime() + index
                }_${document.file.name}`;
                let fileWithNewName = renameAndReturnNewFile(
                  document.file,
                  filename
                );
                filesRequestService.push(fileWithNewName);
                filesService[filename] = {
                  comment: null,
                  selected:
                    REQUEST_SERVICE_CONSTANTS.getServiceQuotes(currentObject)
                      .length == 1 &&
                    document.category ==
                      REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      ? true
                      : document.selected
                      ? true
                      : false,
                  supplier: document.supplier_rut
                    ? document.supplier_rut.replaceAll(".", "")
                    : null,
                  type: REQUEST_SERVICE_CONSTANTS.getTypeDocumentFromFile(
                    document,
                    self.form.request_type == REQUEST_SERVICE_MAVERICK
                  ),
                  unit_price: document.price
                    ? Number(
                        document.price.replaceAll(".", "").replaceAll(",", ".")
                      )
                    : null,
                  coin: document.coin || null,
                };
              });
              return [
                ...finalObject,
                {
                  [currentObject.code]: {
                    documents: filesService,
                    quantity: Number(currentObject.quantity),
                    name: currentObject.name,
                    to_quotation:
                      self.form.request_type == REQUEST_SERVICE_MAVERICK
                        ? false
                        : REQUEST_SERVICE_CONSTANTS.getIfserviceNeedQuotation(
                            currentObject
                          ),
                    comments: currentObject.comment
                      ? currentObject.comment
                      : null,
                  },
                },
              ];
            },
            []
          ),
        },
        files: filesRequestService,
      };

      if (existDocumentDuplicatesInService) {
        this.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: `Los documentos adjuntos en cada servicio deben tener un nombre único`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      const response = await this.sendCreateRequestService(payload);
      if (!response || response.status == 500) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (
        response.response?.status == 400 ||
        response.response?.status == 404
      ) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: response?.response?.data?.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 200) {
        this.loading = false;
        this.$refs.tableAddMaterials.resetFilter();
        this.resetFormCreateRequestMaterial();
        let message = response.data.sent_to_sap
          ? "Su solicitud de servicios ha sido creada correctamente"
          : response.data.sent_to_approval_flow
          ? "Su solicitud de servicios ha sido enviada al flujo de aprobaciones correctamente"
          : "Su solicitud de servicios ha sido envada al flujo de cotizaciones, pronto será revisado por el equipo de compras";
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: message,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 7000,
          timerProgressBar: true,
        });
        this.$emit("requestServicesCreated");
      }
      this.loading = false;
      this.closeModalCreateRequestMaterials(true);
    },
    async createRequestMaterials(confirmation = false) {
      if (
        (!this.form.project || !this.form.sap_stage) &&
        !this.$route.query.sap_project
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Proyecto y etapa son requeridos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.request_type) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Tipo de Solicitud es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.currentMaterials.length) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "La Solicitud debe tener al menos un material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (this.currentMaterials.find((item) => item.quantity < 1)) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Todos los Materiales deben tener al menos 1 unidad",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      this.loading = true;
      let response = null;
      let payload = {
        material_request_json: {
          request_type: this.form.request_type,
          description: this.form.description,
          materials: this.currentMaterials.reduce(
            (finalObject, currentObject) => {
              return {
                ...finalObject,
                [currentObject.code]: finalObject[currentObject.code]
                  ? Number(finalObject[currentObject.code]) +
                    Number(currentObject.quantity)
                  : Number(currentObject.quantity),
              };
            },
            {}
          ),
          user: this.user.email,
          origin:
            this.form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
            this.$route.query.sap_project ||
            this.stockItemGroup
              ? "BODEGA FLUXSOLAR"
              : undefined,
          sap_stage:
            this.form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
            this.$route.query.sap_project ||
            this.stockItemGroup
              ? this.form.sap_stage
              : undefined,
          project: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.form.type_destiny == TYPE_DESTINY_CECO_ID
            ? undefined
            : this.form.project.split(" - ")[0],
          cost_center:
            this.form.type_destiny == TYPE_DESTINY_CECO_ID
              ? this.form.ceco.split(" - ")[0]
              : undefined,
          is_maverick: false,
          is_service: false,
        },
        project_code: this.$route.query.sap_project
          ? this.$route.query.sap_project
          : this.form.type_destiny == TYPE_DESTINY_CECO_ID
          ? null
          : this.form.project.split(" - ")[0],
        cost_center:
          this.form.type_destiny == TYPE_DESTINY_CECO_ID
            ? this.form.ceco.split(" - ")[0]
            : null,
        created_by: this.user.email,
        amount: this.calculateTotalMaterials(),
        is_service: false,
        is_maverick: false,
        confirmation,
      };
      response = await this.createRequestApproveMaterials(payload);
      if (response.response?.status == 403) {
        this.loading = false;
        return swal2
          .fire({
            icon: "info",
            title: "Atención",
            text: `Su solicitud está sujeta a aprobación, ya que supera el monto máximo permitido ¿desea crearla igualmente?`,
            confirmButtonText: "Continuar",
            cancelButtonText: "Cancelar",
            showConfirmButton: true,
            showCancelButton: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loading = true;
              await this.createRequestMaterials(true);
            } else {
              this.loading = false;
              this.$refs.tableAddMaterials.resetFilter();
              this.resetFormCreateRequestMaterial();
              this.closeModalCreateRequestMaterials(true);
            }
          });
      }
      if (!response || response.status == 500) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (
        response.response?.status == 400 ||
        response.response?.status == 404
      ) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: response?.response?.data?.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 200) {
        this.loading = false;
        this.$refs.tableAddMaterials.resetFilter();
        this.resetFormCreateRequestMaterial();
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: `${
            response.data.sent_to_erp
              ? response.data.message
              : "Tu solicitud supera el monto máximo asignado, por lo tanto, deberá pasar por el flujo de aprobación"
          } ${
            !response.data.sent_to_erp
              ? ".En este momento serás redirigido a la sección de aprobaciones"
              : ""
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 11000,
          timerProgressBar: true,
        });
        let self = this;
        if (response.data.sent_to_erp) {
          self.$emit("requestMaterialsCreated");
        } else {
          setTimeout(() => {
            self.$router.push({
              name: "requestMaterialsApprovals",
              query: {
                sap_project: this.$route.query.sap_project
                  ? this.$route.query.sap_project
                  : undefined,
              },
            });
          }, 5000);
        }
      }
      this.loading = false;
      this.closeModalCreateRequestMaterials(true);
    },
    calculateTotalMaterials() {
      return this.currentMaterials.reduce((total, currentProduct) => {
        let costProduct =
          currentProduct.quantity * currentProduct.price_reference;
        return (total = total + costProduct);
      }, 0);
    },

    addMaterialsSelected(materials) {
      this.currentMaterials.push(
        ...materials.map((item, index) => {
          return {
            id: item.id,
            quantity: item.quantity,
            code: item.code,
            name: item.name,
            unit: item.unit,
            price: null,
            coin: null,
            comment: null,
            price_reference: item.price,
            documents: [],
          };
        })
      );
      this.currentMaterials.forEach(
        (item, index) => (item.key = `${item.id}-${index}`)
      );
    },
    openModalTableAddMaterials() {
      this.dialogTableAddMaterials = true;
    },
    closeModalTableAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.$refs.tableAddMaterials.resetFilter();
        this.dialogTableAddMaterials = false;
      }
    },
    openModalFilesService(item) {
      this.currrentService = item;
      this.dialogModalFilesService = true;
    },
    closeModalFilesService($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currrentService = null;
        this.dialogModalFilesService = false;
      }
    },
    openModalUpdateCommentService(item) {
      this.currrentService = item;
      this.dialogModalUpdateCommentService = true;
    },
    closeModalUpdateCommentService() {
      this.currrentService = null;
      this.dialogModalUpdateCommentService = false;
    },
    deleteMaterial(material) {
      let self = this;
      swal2
        .fire({
          icon: "error",
          title: `¿Deseas eliminar este material: ${material.name}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.currentMaterials = self.currentMaterials.filter((item) => {
              return item.key !== material.key;
            });
            swal2.fire({
              icon: "success",
              title: "Excelente",
              text: "Material eliminado correctamente",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 6000,
              timerProgressBar: true,
            });
          }
        });
    },
    executeCloseModalCreateRequestMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.filters.filter = "";
        this.closeModalCreateRequestMaterials($event);
      }
    },
    resetFormCreateRequestMaterial() {
      this.form = {
        request_type: null,
        description: "",
        project: null,
        sap_stage: null,
      };
      this.currentMaterials = [];
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      cecos: "requestMaterialsSap/cecos",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
      projectInfo: "project/projectInfo",
    }),
    wrappedTableHeaders() {
      if (this.stockItemGroup) {
        return this.tableHeaders;
      }
      return [
        ...this.tableHeaders,
        // {
        //   text: "Precio Referencia",
        //   value: "price",
        //   width: "8%",
        //   align: "center",
        //   sortable: true,
        // },
      ];
    },
    getCurrentProjectFromCode() {
      if (
        (this.form.project || this.$route.query.sap_project) &&
        this.projects.items
      ) {
        return this.projects.items.find(
          (item) =>
            item.code == this.form.project?.split(" - ")[0] ||
            item.code == this.$route.query.sap_project
        );
      }
      return null;
    },
  },
  watch: {
    projects(newProjects) {
      if (newProjects.items && this.$route.query.sap_project) {
        this.getStagesFromProject();
      }
    },
  },
};
</script>

<style>
.my-swal-popup {
  z-index: 30000 !important;
}
</style>
