var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-simple-table',{staticClass:"elevation-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Acción")]),_c('th',{staticClass:"text-left"},[_vm._v("Código de SAP")]),_c('th',{staticClass:"text-left"},[_vm._v("Descripción")]),_c('th',{staticClass:"text-center"},[_vm._v("Precio")]),_c('th',{staticClass:"text-left"},[_vm._v("Tipo de Cambio")]),_c('th',{staticClass:"text-right"},[_vm._v("Cantidad Solicitada")]),_c('th',{staticClass:"text-center"},[_vm._v("Estatus")])])]),_c('tbody',[_vm._l((_vm.requestService.items),function(service){return _c('tr',{key:service.id},[_c('td',[_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"blue darken-3",attrs:{"fab":"","x-small":"","outlined":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":""}},[(
                    _vm.requestingUserModule &&
                      _vm.serviceIsStatusReady(service) &&
                      (_vm.requestIsStatusInUserValidationVariant(
                        _vm.requestService
                      ) ||
                        _vm.requestIsStatusInQuotation(_vm.requestService)) &&
                      service.to_quotation
                  )?_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusServiceToQuotation(service)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" ⚠️ ")]),_vm._v(" Solicitar Nueva Cotización ")])],1):_vm._e(),(
                    _vm.serviceIsStatusInReviewOrInQuotation(service) &&
                      !_vm.requestingUserModule
                  )?_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusService(service)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" 👌 ")]),_vm._v(" Enviar Cotización a Validación ")])],1):_vm._e(),(
                    _vm.serviceIsStatusInPurchaseValidationVariant(service) &&
                      !_vm.requestingUserModule
                  )?_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusServiceToApprove(service)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" 👌 ")]),_vm._v(" Aprobar Cotización ")])],1):_vm._e(),(
                    _vm.serviceIsStatusInPurchaseValidationVariant(service) &&
                      !_vm.requestingUserModule
                  )?_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.updateStatusServiceToQuotation(service, true)}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" ⚠️ ")]),_vm._v(" Enviar a Cotización por Abastecimiento ")])],1):_vm._e(),_c('v-list-item',{staticClass:"mb-0 mt-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.openModalCommentsService(_vm.requestService.id, service)}}},[_c('v-badge',{attrs:{"content":service.comments && service.comments.length
                          ? service.comments.length
                          : '0',"color":service.comments && service.comments.length
                          ? 'success'
                          : 'red',"overlap":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" mdi-comment-outline ")])],1),_c('span',{staticClass:"mx-2"},[_vm._v(" Comentarios ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"pointer",attrs:{"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalFilesUploadService(_vm.requestService.id, service)}}},[_c('v-badge',{attrs:{"bordered":"","color":service.documents.length ? 'green' : 'red',"content":service.documents.length
                          ? service.documents.length
                          : '0',"overlap":""}},[_c('v-icon',{},[_vm._v(" mdi-file-multiple-outline ")])],1),_c('span',{staticClass:"mx-3"},[_vm._v(" Documentos ")])],1)],1),(
                    _vm.requestingUserModule &&
                      _vm.requestIsStatusInUserValidationVariant(_vm.requestService)
                  )?_c('v-list-item',{staticClass:"mb-0"},[_c('v-list-item-title',{staticClass:"pointer",attrs:{"x-small":""},on:{"click":function($event){return _vm.deleteService(service)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v(" mdi-delete-outline ")]),_vm._v(" Eliminar Servicio ")],1)],1):_vm._e()],1)],1)],1),_c('td',[_c('p',{staticClass:"my-0 text-left"},[_vm._v(" "+_vm._s(service.sap_code)+" ")])]),_c('td',[_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(service.name ? service.name : "-")+" ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"my-0 mt-1",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(service.unit_price ? ("$ " + (new Intl.NumberFormat("de-DE").format( Number(service.unit_price) ))) : "Por definir")+" ")])])]),_c('td',[_c('p',{staticClass:"my-0 text-left"},[_vm._v(" "+_vm._s(service.coin ? service.coin : "CLP")+" ")])]),_c('td',[_c('p',{staticClass:"my-0 text-right"},[_vm._v(" "+_vm._s(service.quantity)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(
                      _vm.requestingUserModule &&
                        _vm.serviceIsStatusInQuotation(service)
                    )?_c('v-btn',_vm._g(_vm._b({staticClass:"pointer",attrs:{"icon":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.updateQuantityService(service)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modificar Cantidad")])])],1)]),_c('td',[_c('v-chip',{staticClass:"mt-2 text-white",attrs:{"label":"","small":"","color":_vm.getStatusColor(service.status)}},[_vm._v(" "+_vm._s(_vm.getStatusCorrection(service.status))+" ")])],1)])}),(_vm.requestService.items.length == 0)?_c('tr',[_c('td',{staticClass:"bg-white",attrs:{"colspan":"10"}},[_c('small',[_vm._v(" Sin Servicios "+_vm._s(_vm.deletedItems ? "Eliminados" : "")+" ")])])]):_vm._e()],2)]},proxy:true}])}),_c('modal-comments-service',{attrs:{"modalAction":_vm.dialogCommmentsService,"closeModalComments":_vm.closeModalCommentsService,"currentService":_vm.currentServiceComments,"requestId":_vm.currentRequestServiceId},on:{"commentAdded":_vm.emitGetRequestsService}}),_c('modal-files-service',{ref:"modalFilesUploadService",attrs:{"modalAction":_vm.dialogFilesUploadService,"closeModalFilesService":_vm.closeModalFilesUploadService,"userCreateRequestService":false,"currentService":_vm.currentFilesUploadService,"currentRequest":_vm.requestService,"requestId":_vm.currentRequestServiceId,"requestingUserModule":_vm.requestingUserModule,"typeRequestService":_vm.requestService ? _vm.requestService.request_type : null},on:{"filesUploaded":_vm.emitGetRequestsServiceAndUpdateFiles,"updateDocumentsCurrentService":_vm.emitGetRequestsServiceAndUpdateFiles}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }